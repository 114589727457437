// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: false,
  qa: false,
  dev: false,
  local: true,
  pubNubChannelPrefix: 'dev_',
  staging: false,
  // appUrl: 'http://13.232.173.166/'
  // appUrl: 'http://3.108.140.72/'
  appUrl: 'https://cms.backend.msedu.in/'
};
