import {RouteInfo} from './sidebar.metadata';

export const ROUTES: RouteInfo[] = [
  {
    path: '/dashboard',
    title: 'Dashboard',
    icon: 'icon-screen-desktop',
    class: '',
    badge: '',
    badgeClass: '',
    isExternalLink: false,
    submenu: [],
    availableTo: ['SUPER_ADMIN', 'ADMIN', 'COURIER', 'EXECUTIVE']
  },
  {
    path: '/admins',
    title: 'Admins',
    icon: 'fas fa-user-shield',
    class: '',
    badge: '',
    badgeClass: '',
    isExternalLink: false,
    submenu: [],
    availableTo: ['SUPER_ADMIN']
  },
  {
    path: '/executives',
    title: 'Executives',
    icon: 'fas fa-headset',
    class: '',
    badge: '',
    badgeClass: '',
    isExternalLink: false,
    submenu: [],
    availableTo: ['SUPER_ADMIN', 'ADMIN']
  },
  {
    path: '/courier-executives',
    title: 'Courier Executives',
    icon: 'fas fa-id-card-alt',
    class: '',
    badge: '',
    badgeClass: '',
    isExternalLink: false,
    submenu: [],
    availableTo: ['SUPER_ADMIN', 'ADMIN']
  },
  {
    path: '/statuses',
    title: 'Statuses',
    icon: 'fas fa-check-circle',
    class: '',
    badge: '',
    badgeClass: '',
    isExternalLink: false,
    submenu: [],
    availableTo: ['SUPER_ADMIN', 'ADMIN']
  },
  {
    path: '/labels',
    title: 'Labels',
    icon: 'fas fa-tags',
    class: '',
    badge: '',
    badgeClass: '',
    isExternalLink: false,
    submenu: [],
    availableTo: ['SUPER_ADMIN', 'ADMIN']
  },
  {
    path: '/subjects',
    title: 'Subjects',
    icon: 'fas fa-bug',
    class: '',
    badge: '',
    badgeClass: '',
    isExternalLink: false,
    submenu: [],
    availableTo: ['SUPER_ADMIN', 'ADMIN']
  },
  {
    path: '/centres',
    title: 'Centres',
    icon: 'fas fa-university',
    class: '',
    badge: '',
    badgeClass: '',
    isExternalLink: false,
    submenu: [],
    availableTo: ['SUPER_ADMIN', 'COURIER', 'EXECUTIVE', 'ADMIN']
  },
  {
    path: '/couriers',
    title: 'Couriers',
    icon: 'fas fa-envelope-open',
    class: '',
    badge: '',
    badgeClass: '',
    isExternalLink: false,
    submenu: [],
    availableTo: ['SUPER_ADMIN', 'COURIER', 'ADMIN']
  },
  {
    path: '/conversations',
    title: 'Conversations',
    icon: 'fas fa-comments',
    class: '',
    badge: '',
    badgeClass: '',
    isExternalLink: false,
    submenu: [],
    availableTo: ['EXECUTIVE']
  },
  {
    path: '/notifications',
    title: 'Notifications',
    icon: 'fas fa-globe-asia',
    class: '',
    badge: '',
    badgeClass: '',
    isExternalLink: false,
    submenu: [],
    availableTo: ['SUPER_ADMIN']
  },
  {
    path: '/activity-logs',
    title: 'Activity Logs',
    icon: 'icon-grid',
    class: '',
    badge: '',
    badgeClass: '',
    isExternalLink: false,
    submenu: [],
    availableTo: ['SUPER_ADMIN', 'EXECUTIVE', 'ADMIN']
  },
  /*{
    path: '',
    title: 'Menu Levels',
    icon: 'icon-layers',
    class: 'has-sub',
    badge: '1',
    badgeClass: 'badge badge-pill badge-danger float-right mr-3 mt-1',
    isExternalLink: false,
    submenu: [
      {path: 'javascript:;', title: 'Second Level', icon: '', class: '', badge: '', badgeClass: '', isExternalLink: true, submenu: []},
      {
        path: '', title: 'Second Level', icon: '', class: 'has-sub', badge: '', badgeClass: '', isExternalLink: false,
        submenu: [
          {
            path: 'javascript:;',
            title: 'Third Level 1.1',
            icon: '',
            class: '',
            badge: '',
            badgeClass: '',
            isExternalLink: true,
            submenu: []
          },
          {
            path: 'javascript:;',
            title: 'Third Level 1.2',
            icon: '',
            class: '',
            badge: '',
            badgeClass: '',
            isExternalLink: true,
            submenu: []
          },
        ]
      },
    ]
  },
  {
    path: '/changelog', title: 'ChangeLog', icon: 'icon-doc', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: []
  },
  {
    path: 'http://pixinvent.com/demo/convex-angular-bootstrap-admin-dashboard-template/documentation',
    title: 'Documentation',
    icon: 'icon-book-open',
    class: '',
    badge: '',
    badgeClass: '',
    isExternalLink: true,
    submenu: []
  }*/
];
