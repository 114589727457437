import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {CentreService} from '../../../shared/services/centre.service';
import {StateService} from '../../../shared/services/state.service';
import {AlertService} from '../../../shared/services/alert.service';
import {Location} from '@angular/common';

@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.scss']
})
export class PaymentComponent implements OnInit {
  addPaymentForm: FormGroup;
  submitted: any = false;
  submitting: any = false;
  states: any = [];
  id: any;
  previouslyUpdatedBy: any;

  constructor(private router: Router,
              private fb: FormBuilder,
              private centreService: CentreService,
              private stateService: StateService,
              public activatedRoute: ActivatedRoute,
              private alertService: AlertService,
              private _location: Location) {
  }

  ngOnInit() {
    this.id = this.activatedRoute.snapshot.params['id'];
    this.refresh();
  }

  refresh() {
    this.addPaymentForm = this.fb.group({
      'noOfStudentReRegistration': ['0'],
      'amountReRegistration': ['0'],
      'noOfStudentFresh': ['0'],
      'amountFresh': ['0'],
      'noOfStudentBack': ['0'],
      'amountBack': ['0'],
      'noOfStudentOthers': ['0'],
      'amountOthers': ['0'],
      'previousAmount': ['0'],
      'currentAmount': [{value: '0', 'disabled': true}],
      'receivedAmount': ['0'],
      'balanceAmount': [{value: '0', 'disabled': true}]
    });
    if (this.id) {
      this.detail(this.id);
    }
  }

  detail(id) {
    this.centreService.detail(parseInt(id)).subscribe(data => {
      if (data) {
        this.previouslyUpdatedBy = (data && data['executive_payment']) ? data['executive_payment'] : '';
        this.addPaymentForm.patchValue({
          'noOfStudentReRegistration': (data && data.no_of_student_reregistration) ? data.no_of_student_reregistration : 0,
          'amountReRegistration': (data && data.amount_reregistration) ? data.amount_reregistration : 0,
          'noOfStudentFresh': (data && data.no_of_student_fresh) ? data.no_of_student_fresh : 0,
          'amountFresh': (data && data.amount_fresh) ? data.amount_fresh : 0,
          'noOfStudentBack': (data && data.no_of_student_back) ? data.no_of_student_back : 0,
          'amountBack': (data && data.amount_back) ? data.amount_back : 0,
          'noOfStudentOthers': (data && data.no_of_student_others) ? data.no_of_student_others : 0,
          'amountOthers': (data && data.amount_others) ? data.amount_others : 0,
          'previousAmount': (data && data.previous_amount) ? data.previous_amount : 0,
          'currentAmount': (data && data.current_amount) ? data.current_amount : 0,
          'receivedAmount': (data && data.received_amount) ? data.received_amount : 0,
          'balanceAmount': (data && data.balance_amount) ? data.balance_amount : 0
        });
      }
    }, error => {
      this.back();
    });
  }

  calculate() {
    const amountReRegistration = this.addPaymentForm.value.amountReRegistration ? this.addPaymentForm.value.amountReRegistration : 0;
    const amountFresh = this.addPaymentForm.value.amountFresh ? this.addPaymentForm.value.amountFresh : 0;
    const amountBack = this.addPaymentForm.value.amountBack ? this.addPaymentForm.value.amountBack : 0;
    const amountOthers = this.addPaymentForm.value.amountOthers ? this.addPaymentForm.value.amountOthers : 0;
    const currentAmount = parseFloat(amountReRegistration) + parseFloat(amountFresh) + parseFloat(amountBack) + parseFloat(amountOthers);
    const previousAmount = this.addPaymentForm.value.previousAmount ? this.addPaymentForm.value.previousAmount : 0;
    const receivedAmount = this.addPaymentForm.value.receivedAmount ? this.addPaymentForm.value.receivedAmount : 0;
    const totalAmount = parseFloat(previousAmount) + parseFloat(String(currentAmount));
    const balanceAmount = parseFloat(String(totalAmount)) - parseFloat(String(receivedAmount));
    this.addPaymentForm.patchValue({
      'currentAmount': currentAmount ? Number(currentAmount).toFixed(2) : 0,
      'balanceAmount': balanceAmount ? Number(balanceAmount).toFixed(2) : 0
    });
  }

  back() {
    // this.router.navigateByUrl('centres');
    this._location.back();
  }

  update() {
    this.submitted = true;
    if (!this.addPaymentForm.valid) {
      return;
    }
    if (this.submitted) {
      const params = {
        'no_of_student_reregistration': (this.addPaymentForm.value && this.addPaymentForm.value.noOfStudentReRegistration) ? this.addPaymentForm.value.noOfStudentReRegistration : 0,
        'amount_reregistration': (this.addPaymentForm.value && this.addPaymentForm.value.amountReRegistration) ? this.addPaymentForm.value.amountReRegistration : 0,
        'no_of_student_fresh': (this.addPaymentForm.value && this.addPaymentForm.value.noOfStudentFresh) ? this.addPaymentForm.value.noOfStudentFresh : 0,
        'amount_fresh': (this.addPaymentForm.value && this.addPaymentForm.value.amountFresh) ? this.addPaymentForm.value.amountFresh : 0,
        'no_of_student_back': (this.addPaymentForm.value && this.addPaymentForm.value.noOfStudentBack) ? this.addPaymentForm.value.noOfStudentBack : 0,
        'amount_back': (this.addPaymentForm.value && this.addPaymentForm.value.amountBack) ? this.addPaymentForm.value.amountBack : 0,
        'no_of_student_others': (this.addPaymentForm.value && this.addPaymentForm.value.noOfStudentOthers) ? this.addPaymentForm.value.noOfStudentOthers : 0,
        'amount_others': (this.addPaymentForm.value && this.addPaymentForm.value.amountOthers) ? this.addPaymentForm.value.amountOthers : 0,
        'previous_amount': (this.addPaymentForm.value && this.addPaymentForm.value.previousAmount) ? this.addPaymentForm.value.previousAmount : 0,
        'current_amount': (this.addPaymentForm.getRawValue() && this.addPaymentForm.getRawValue().currentAmount) ? this.addPaymentForm.getRawValue().currentAmount : 0,
        'received_amount': (this.addPaymentForm.value && this.addPaymentForm.value.receivedAmount) ? this.addPaymentForm.value.receivedAmount : 0,
        'balance_amount': (this.addPaymentForm.getRawValue() && this.addPaymentForm.getRawValue().balanceAmount) ? this.addPaymentForm.getRawValue().balanceAmount : 0
      };
      this.submitting = true;
      this.centreService.paymentUpdate(this.id, params).subscribe(data => {
        this.submitting = false;
        this.submitted = false;
        this.back();
      }, error => {
        this.submitting = false;
        this.submitted = false;
        if (error && error['error'] && error['error']['errors'] && error['error'].hasOwnProperty('errors')) {
          if (error['error']['errors'].no_of_student_reregistration && error['error']['errors'].no_of_student_reregistration.length > 0) {
            this.alertService.showErrors(error['error']['errors'].no_of_student_reregistration[0]);
            return;
          }
          if (error['error']['errors'].amount_reregistration && error['error']['errors'].amount_reregistration.length > 0) {
            this.alertService.showErrors(error['error']['errors'].amount_reregistration[0]);
            return;
          }
          if (error['error']['errors'].no_of_student_fresh && error['error']['errors'].no_of_student_fresh.length > 0) {
            this.alertService.showErrors(error['error']['errors'].no_of_student_fresh[0]);
            return;
          }
          if (error['error']['errors'].amount_fresh && error['error']['errors'].amount_fresh.length > 0) {
            this.alertService.showErrors(error['error']['errors'].amount_fresh[0]);
            return;
          }
          if (error['error']['errors'].no_of_student_back && error['error']['errors'].no_of_student_back.length > 0) {
            this.alertService.showErrors(error['error']['errors'].no_of_student_back[0]);
            return;
          }
          if (error['error']['errors'].amount_back && error['error']['errors'].amount_back.length > 0) {
            this.alertService.showErrors(error['error']['errors'].amount_back[0]);
            return;
          }
          if (error['error']['errors'].no_of_student_others && error['error']['errors'].no_of_student_others.length > 0) {
            this.alertService.showErrors(error['error']['errors'].no_of_student_others[0]);
            return;
          }
          if (error['error']['errors'].amount_others && error['error']['errors'].amount_others.length > 0) {
            this.alertService.showErrors(error['error']['errors'].amount_others[0]);
            return;
          }
          if (error['error']['errors'].previous_amount && error['error']['errors'].previous_amount.length > 0) {
            this.alertService.showErrors(error['error']['errors'].previous_amount[0]);
            return;
          }
          if (error['error']['errors'].current_amount && error['error']['errors'].current_amount.length > 0) {
            this.alertService.showErrors(error['error']['errors'].current_amount[0]);
            return;
          }
          if (error['error']['errors'].received_amount && error['error']['errors'].received_amount.length > 0) {
            this.alertService.showErrors(error['error']['errors'].received_amount[0]);
            return;
          }
          if (error['error']['errors'].balance_amount && error['error']['errors'].balance_amount.length > 0) {
            this.alertService.showErrors(error['error']['errors'].balance_amount[0]);
            return;
          }
        }
      });
    }
  }
}
