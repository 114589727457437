import {NgModule} from '@angular/core';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {AppRoutingModule} from './app-routing.module';
import {SharedModule} from './shared/shared.module';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {AppComponent} from './app.component';
import {ContentLayoutComponent} from './layouts/content/content-layout.component';
import {FullLayoutComponent} from './layouts/full/full-layout.component';
import {AuthService} from './shared/auth/auth.service';
import {AuthGuard} from './shared/auth/auth-guard.service';
import * as $ from 'jquery';
import {LoginComponent} from './layouts/login/login.component';
import {SnotifyModule, SnotifyService, ToastDefaults} from 'ng-snotify';
import {HtpInterceptor} from './shared/services/interceptor.service';
import {GlobalService, MomentService} from './shared/services/global.service';
import {AlertService} from './shared/services/alert.service';
import {HttpClientModule} from '@angular/common/http';
import {ReactiveFormsModule} from '@angular/forms';
import {DashboardComponent} from './pages/dashboard/dashboard.component';
import {ExecutivesComponent} from './pages/executives/executives.component';
import {CentresComponent} from './pages/centres/centres.component';
import {MomentDatePipe} from './shared/pipes/moment-date.pipe';
import {StatusesComponent} from './pages/statuses/statuses.component';
import {LabelsComponent} from './pages/labels/labels.component';
import {IssuesComponent} from './pages/issues/issues.component';
import {ActivityLogsComponent} from './pages/activity-logs/activity-logs.component';
import {AddExecutiveComponent} from './pages/executives/add-executive/add-executive.component';
import {AddCentreComponent} from './pages/centres/add-centre/add-centre.component';
import {AddIssueComponent} from './pages/issues/add-issue/add-issue.component';
import {AddStatusComponent} from './pages/statuses/add-status/add-status.component';
import {ColorPickerModule} from 'ngx-color-picker';
import {AddLabelComponent} from './pages/labels/add-label/add-label.component';
import {MyProfileComponent} from './pages/my-profile/my-profile.component';
import {SubjectsComponent} from './pages/subjects/subjects.component';
import {AddSubjectComponent} from './pages/subjects/add-subject/add-subject.component';
import {PaymentComponent} from './pages/centres/payment/payment.component';
import {CouriersComponent} from './pages/centres/couriers/couriers.component';
import {AddCourierComponent} from './pages/centres/couriers/add-courier/add-courier.component';
import {BsDatepickerModule} from 'ngx-bootstrap/datepicker';
import {CouriersListComponent} from './pages/couriers-list/couriers-list.component';
import {NgxPrintModule} from 'ngx-print';
import {ConversationsComponent} from './pages/conversations/conversations.component';
import {AdminsComponent} from './pages/admins/admins.component';
import {AddAdminComponent} from './pages/admins/add-admin/add-admin.component';
import {CourierExecutivesComponent} from './pages/courier-executives/courier-executives.component';
import {AddCourierExecutiveComponent} from './pages/courier-executives/add-courier-executive/add-courier-executive.component';
import {NotificationsComponent} from './pages/notifications/notifications.component';
import {OwlDateTimeModule, OwlNativeDateTimeModule} from 'ng-pick-datetime';
import {AddNotificationComponent} from './pages/notifications/add-notification/add-notification.component';
import {NotFoundComponent} from './pages/not-found/not-found.component';

@NgModule({
  declarations: [
    AppComponent,
    FullLayoutComponent,
    ContentLayoutComponent,
    LoginComponent,
    MomentDatePipe,
    DashboardComponent,
    ExecutivesComponent,
    AdminsComponent,
    AddAdminComponent,
    CentresComponent,
    StatusesComponent,
    LabelsComponent,
    IssuesComponent,
    ActivityLogsComponent,
    AddExecutiveComponent,
    AddCentreComponent,
    AddIssueComponent,
    AddStatusComponent,
    AddLabelComponent,
    MyProfileComponent,
    SubjectsComponent,
    AddSubjectComponent,
    PaymentComponent,
    CouriersComponent,
    AddCourierComponent,
    CouriersListComponent,
    ConversationsComponent,
    CourierExecutivesComponent,
    AddCourierExecutiveComponent,
    NotificationsComponent,
    AddNotificationComponent,
    NotFoundComponent
  ],
  imports: [
    BrowserAnimationsModule,
    AppRoutingModule,
    SharedModule,
    HttpClientModule,
    NgbModule,
    ReactiveFormsModule,
    SnotifyModule,
    ColorPickerModule,
    BsDatepickerModule.forRoot(),
    NgxPrintModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule
  ],
  providers: [
    AuthService,
    AuthGuard,
    HtpInterceptor,
    GlobalService,
    AlertService,
    {provide: 'SnotifyToastConfig', useValue: ToastDefaults},
    SnotifyService,
    MomentService
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
