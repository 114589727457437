import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {StorageService} from '../../shared/services/storage.service';
import {ConversationService} from '../../shared/services/conversation.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ExecutiveService} from '../../shared/services/executive.service';

@Component({
  selector: 'app-conversations',
  templateUrl: './conversations.component.html',
  styleUrls: ['./conversations.component.scss']
})
export class ConversationsComponent implements OnInit {
  conversations = [];
  user: any;
  addConversionForm: FormGroup;
  submitted = false;
  submitting = false;
  id: any;
  selectedConversation: any;
  selectedExecutive: any;
  pagination = {
    page: 1,
    total: null,
    perpage: 15,
    pages: null
  };

  constructor(private conversationService: ConversationService,
              private router: Router,
              public modalService: NgbModal,
              private fb: FormBuilder,
              private activatedRoute: ActivatedRoute,
              private executiveService: ExecutiveService) {
  }

  ngOnInit() {
    this.user = StorageService.getItem('self');
    this.id = this.activatedRoute.snapshot.params['id'];
    this.refresh();
  }

  refresh() {
    this.addConversionForm = this.fb.group({
      'message': ['', Validators.required]
    });
    if ((this.user.user_type === 'SUPER_ADMIN' || this.user.user_type === 'ADMIN') && this.id) {
      this.detail();
    } else if (this.user.user_type === 'EXECUTIVE') {
      this.get();
    } else {
      this.router.navigateByUrl('');
    }
  }

  detail() {
    this.executiveService.detail(parseInt(this.id)).subscribe(data => {
      if (data) {
        this.selectedExecutive = data;
        this.getExecutiveConversation();
      } else {
        this.router.navigateByUrl('executives');
      }
    }, error => {
      this.router.navigateByUrl('executives');
    });
  }

  getExecutiveConversation(params: any = {}) {
    params.page = this.pagination.page;
    params.per_page = this.pagination.perpage;
    this.conversationService.getExecutivesConversation(this.id, params).subscribe(data => {
      if (data && data['data'].length > 0) {
        this.conversations = data['data'];
        this.pagination = {
          page: data.current_page,
          total: data.total,
          perpage: data.per_page,
          pages: data.last_page,
        }
      } else {
        this.conversations = [];
      }
    }, error => {
      this.submitting = false;
      this.submitted = false;
    });
  }

  get(params: any = {}) {
    params.page = this.pagination.page;
    params.per_page = this.pagination.perpage;
    this.conversationService.get(params).subscribe(data => {
      if (data && data['data'].length > 0) {
        this.conversations = data['data'];
        this.pagination = {
          page: data.current_page,
          total: data.total,
          perpage: data.per_page,
          pages: data.last_page,
        }
      } else {
        this.conversations = [];
      }
    }, error => {
      this.submitting = false;
      this.submitted = false;
    });
  }

  conversationModel(content) {
    this.addConversionForm.patchValue({
      'message': ''
    });
    this.selectedConversation = undefined;
    this.submitted = false;
    this.submitting = false;
    this.modalService.open(content, {'size': 'lg'});
  }

  submit() {
    this.submitted = true;
    if (!this.addConversionForm.valid) {
      return;
    }
    if (this.submitted) {
      const params = {
        'message': (this.addConversionForm.value && this.addConversionForm.value.message) ? this.addConversionForm.value.message : ''
      };
      this.submitting = true;
      this.conversationService.create(params).subscribe(data => {
        if (data) {
          this.submitting = false;
          this.submitted = false;
          this.modalService.dismissAll('');
          this.get();
        }
      }, error => {
        this.submitting = false;
        this.submitted = false;
      });
    }
  }

  update() {
    this.submitted = true;
    if (!this.addConversionForm.valid) {
      return;
    }
    if (this.submitted) {
      const params = {
        'message': (this.addConversionForm.value && this.addConversionForm.value.message) ? this.addConversionForm.value.message : ''
      };
      this.submitting = true;
      this.conversationService.update(this.selectedConversation.id, params).subscribe(data => {
        this.submitting = false;
        this.submitted = false;
        this.modalService.dismissAll('');
        this.get();
      }, error => {
        this.submitting = false;
        this.submitted = false;
      });
    }
  }

  delete(id) {
    this.conversationService.delete(id).subscribe(data => {
      this.get({});
    });
  }

  navigateTo(url) {
    this.router.navigateByUrl(url);
  }

  edit(content, conversation) {
    this.submitting = false;
    this.submitted = false;
    this.selectedConversation = conversation;
    this.addConversionForm.patchValue({
      'message': conversation.message
    });
    this.modalService.open(content, {'size': 'lg'});
  }

  onPageChange(page) {
    this.pagination.page = page;
    this.get();
  }
}
