import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {CentreService} from '../../../shared/services/centre.service';
import {StatusService} from '../../../shared/services/status.service';
import {LabelService} from '../../../shared/services/label.service';
import {IssueService} from '../../../shared/services/issue.service';
import {SubjectService} from '../../../shared/services/subject.service';
import {Location} from '@angular/common';

@Component({
  selector: 'app-add-issue',
  templateUrl: './add-issue.component.html',
  styleUrls: ['./add-issue.component.scss']
})
export class AddIssueComponent implements OnInit {
  addIssueForm: FormGroup;
  submitted: any = false;
  submitting: any = false;
  statuses: any = [];
  subjects: any = [];
  labels: any = [];
  centre: any;

  constructor(private router: Router,
              private fb: FormBuilder,
              private activatedRoute: ActivatedRoute,
              private centreService: CentreService,
              private statusService: StatusService,
              private labelService: LabelService,
              private issueService: IssueService,
              private subjectService: SubjectService,
              private _location: Location) {
  }

  ngOnInit() {
    this.refresh();
  }

  refresh() {
    this.addIssueForm = this.fb.group({
      'subject_id': ['', Validators.required],
      'description': ['', Validators.required],
      'status_id': ['', Validators.required],
      'label_id': ['', Validators.required]
    });
    this.detail();
    this.getStatus();
    this.getLabels();
    this.getSubject();
  }

  detail() {
    const id = parseInt(this.activatedRoute.snapshot.params['id']);
    this.centreService.detail(id).subscribe(data => {
      if (data) {
        this.centre = data;
      }
    }, error => {
      this.back();
    });
  }

  getStatus() {
    this.statusService.get({'page': -1, 'status': 'ACTIVE'}).subscribe(data => {
      if (data && data['data'].length > 0) {
        this.statuses = data['data'];
      }
    });
  }

  getSubject() {
    this.subjectService.get({'page': -1, 'status': 'ACTIVE'}).subscribe(data => {
      if (data && data['data'].length > 0) {
        this.subjects = data['data'];
      }
    });
  }

  getLabels() {
    this.labelService.get({'page': -1, 'status': 'ACTIVE'}).subscribe(data => {
      if (data && data['data'].length > 0) {
        this.labels = data['data'];
      }
    });
  }

  back() {
    // this.router.navigateByUrl('centre/' + this.centre.id + '/issues');
    this._location.back();
  }

  submit() {
    this.submitted = true;
    if (!this.addIssueForm.valid) {
      return;
    }
    if (this.submitted) {
      const params = {
        'subject_id': (this.addIssueForm.value && this.addIssueForm.value.subject_id) ? this.addIssueForm.value.subject_id : '',
        'description': (this.addIssueForm.value && this.addIssueForm.value.description) ? this.addIssueForm.value.description : '',
        'status_id': (this.addIssueForm.value && this.addIssueForm.value.status_id) ? this.addIssueForm.value.status_id : '',
        'label_id': (this.addIssueForm.value && this.addIssueForm.value.label_id) ? this.addIssueForm.value.label_id : ''
      };
      this.submitting = true;
      this.issueService.create(this.centre.id, params).subscribe(data => {
        if (data) {
          this.submitting = false;
          this.submitted = false;
          this.back();
        }
      }, error => {
        this.submitting = false;
        this.submitted = false;
      });
    }
  }
}
