import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {CentreService} from '../../../shared/services/centre.service';
import {AlertService} from '../../../shared/services/alert.service';
import {StateService} from '../../../shared/services/state.service';
import {Location} from '@angular/common';
import {StorageService} from '../../../shared/services/storage.service';

@Component({
  selector: 'app-add-centre',
  templateUrl: './add-centre.component.html',
  styleUrls: ['./add-centre.component.scss']
})
export class AddCentreComponent implements OnInit {
  addCentreForm: FormGroup;
  submitted: any = false;
  submitting: any = false;
  states: any = [];
  id: any;
  user: any;

  constructor(private router: Router,
              private fb: FormBuilder,
              private centreService: CentreService,
              private stateService: StateService,
              public activatedRoute: ActivatedRoute,
              private alertService: AlertService,
              private _location: Location) {
  }

  ngOnInit() {
    this.user = StorageService.getItem('self');
    this.id = this.activatedRoute.snapshot.params['id'];
    this.refresh();
  }

  refresh() {
    this.addCentreForm = this.fb.group({
      'name': ['', Validators.required],
      'code': ['', Validators.required],
      'address': ['', Validators.required],
      'pin': ['', Validators.required],
      'state_id': ['', Validators.required],
      'email': ['', Validators.compose([Validators.required, Validators.pattern('^(([^<>()[\\]\\\\.,;:\\s@\\"]+(\\.[^<>()[\\]\\\\.,;:\\s@\\"]+)*)|(\\".+\\"))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))$')])],
      'contact': ['', Validators.compose([Validators.required, Validators.pattern('^[5-9]{1}[0-9]{9}$')])]
    });
    this.getStates();
    if (this.id) {
      this.detail(this.id);
    }
  }

  detail(id) {
    this.centreService.detail(parseInt(id)).subscribe(data => {
      if (this.user.user_type === 'EXECUTIVE') {
        if (data['executive_id'] === this.user['userId']) {
          this.addCentreForm.patchValue({
            'name': (data && data.name) ? data.name : '',
            'code': (data && data.code) ? data.code : '',
            'address': (data && data.address) ? data.address : '',
            'pin': (data && data.pin) ? data.pin : '',
            'state_id': (data && data.state_id) ? data.state_id : '',
            'email': (data && data.email) ? data.email : '',
            'contact': (data && data.contact) ? data.contact : ''
          });
        } else {
          this.back();
        }
      } else {
        this.addCentreForm.patchValue({
          'name': (data && data.name) ? data.name : '',
          'code': (data && data.code) ? data.code : '',
          'address': (data && data.address) ? data.address : '',
          'pin': (data && data.pin) ? data.pin : '',
          'state_id': (data && data.state_id) ? data.state_id : '',
          'email': (data && data.email) ? data.email : '',
          'contact': (data && data.contact) ? data.contact : ''
        });
      }
    }, error => {
      this.back();
    });
  }

  getStates() {
    this.stateService.get().subscribe(data => {
      if (data && data['data'].length > 0) {
        this.states = data['data'];
      }
    });
  }

  back() {
    //this.router.navigateByUrl('centres');
    this._location.back();
  }

  submit() {
    this.submitted = true;
    if (!this.addCentreForm.valid) {
      return;
    }
    if (this.submitted) {
      const params = {
        'name': (this.addCentreForm.value && this.addCentreForm.value.name) ? this.addCentreForm.value.name : '',
        'code': (this.addCentreForm.value && this.addCentreForm.value.code) ? this.addCentreForm.value.code : '',
        'address': (this.addCentreForm.value && this.addCentreForm.value.address) ? this.addCentreForm.value.address : '',
        'pin': (this.addCentreForm.value && this.addCentreForm.value.pin) ? this.addCentreForm.value.pin : '',
        'state_id': (this.addCentreForm.value && this.addCentreForm.value.state_id) ? this.addCentreForm.value.state_id : '',
        'email': (this.addCentreForm.value && this.addCentreForm.value.email) ? this.addCentreForm.value.email : '',
        'contact': (this.addCentreForm.value && this.addCentreForm.value.contact) ? this.addCentreForm.value.contact : ''
      };
      this.submitting = true;
      this.centreService.create(params).subscribe(data => {
        if (data) {
          this.submitting = false;
          this.submitted = false;
          this.back();
        }
      }, error => {
        this.submitting = false;
        this.submitted = false;
        if (error && error['error'] && error['error']['errors'] && error['error'].hasOwnProperty('errors')) {
          if (error['error']['errors'].email && error['error']['errors'].email.length > 0) {
            this.alertService.showErrors(error['error']['errors'].email[0]);
            return;
          }
          if (error['error']['errors'].contact && error['error']['errors'].contact.length > 0) {
            this.alertService.showErrors(error['error']['errors'].contact[0]);
            return;
          }
        }
      });
    }
  }

  update() {
    this.submitted = true;
    if (!this.addCentreForm.valid) {
      return;
    }
    if (this.submitted) {
      const params = {
        'name': (this.addCentreForm.value && this.addCentreForm.value.name) ? this.addCentreForm.value.name : '',
        'code': (this.addCentreForm.value && this.addCentreForm.value.code) ? this.addCentreForm.value.code : '',
        'address': (this.addCentreForm.value && this.addCentreForm.value.address) ? this.addCentreForm.value.address : '',
        'pin': (this.addCentreForm.value && this.addCentreForm.value.pin) ? this.addCentreForm.value.pin : '',
        'state_id': (this.addCentreForm.value && this.addCentreForm.value.state_id) ? this.addCentreForm.value.state_id : '',
        'email': (this.addCentreForm.value && this.addCentreForm.value.email) ? this.addCentreForm.value.email : '',
        'contact': (this.addCentreForm.value && this.addCentreForm.value.contact) ? this.addCentreForm.value.contact : ''
      };
      this.submitting = true;
      this.centreService.update(this.id, params).subscribe(data => {
        this.submitting = false;
        this.submitted = false;
        this.back();
      }, error => {
        this.submitting = false;
        this.submitted = false;
        if (error && error['error'] && error['error']['errors'] && error['error'].hasOwnProperty('errors')) {
          if (error['error']['errors'].email && error['error']['errors'].email.length > 0) {
            this.alertService.showErrors(error['error']['errors'].email[0]);
            return;
          }
          if (error['error']['errors'].contact && error['error']['errors'].contact.length > 0) {
            this.alertService.showErrors(error['error']['errors'].contact[0]);
            return;
          }
        }
      });
    }
  }
}
