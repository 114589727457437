import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {BaseService} from './base.service';
import {Observable} from 'rxjs';
import {AlertService} from './alert.service';
import {GlobalService} from './global.service';
import {AppUrl} from '../constants/app-url';

@Injectable({
  providedIn: 'root'
})
export class CentreService extends BaseService {
  constructor(public http: HttpClient,
              public alertService: AlertService,
              public globalService: GlobalService) {
    super(http, alertService, globalService);
  }

  create(data): Observable<any> {
    return this.postRequest(AppUrl.CENTRES(), data);
  }

  get(data): Observable<any> {
    return this.getRequest(AppUrl.CENTRES(), data);
  }

  delete(id): Observable<any> {
    return this.deleteRequest(AppUrl.DELETE_CENTER(id));
  }

  detail(id): Observable<any> {
    return this.getRequest(AppUrl.CENTRES(id));
  }

  paymentUpdate(id, data): Observable<any> {
    return this.putRequest(AppUrl.CENTRE_PAYMENT(id), data);
  }

  count(): Observable<any> {
    return this.getRequest(AppUrl.CENTRES_COUNT());
  }

  getCourierCount(): Observable<any> {
    return this.getRequest(AppUrl.COURIERS_COUNT());
  }

  centresAddedByUserCount(id?): Observable<any> {
    if (id) {
      return this.getRequest(AppUrl.CENTRES_ADDED_BY_USER(id));
    } else {
      return this.getRequest(AppUrl.CENTRES_ADDED_BY_USER());
    }
  }

  update(id, data): Observable<any> {
    return this.putRequest(AppUrl.CENTRES(id), data);
  }

  createCourier(id, data): Observable<any> {
    return this.postRequest(AppUrl.COURIERS(id), data);
  }

  getCourier(id, data): Observable<any> {
    return this.getRequest(AppUrl.GET_COURIERS(id), data);
  }



  courierDetail(id): Observable<any> {
    return this.getRequest(AppUrl.DETAILS_COURIER(id));
  }

  updateCourier(id, data): Observable<any> {
    return this.putRequest(AppUrl.UPDATE_COURIERS(id), data);
  }

  deleteCourier(id): Observable<any> {
    return this.deleteRequest(AppUrl.DELETE_COURIER(id));
  }

  getAllCourier(data): Observable<any> {
    return this.getRequest(AppUrl.GET_ALL_COURIERS(), data);
  }

  downloadCentres() {
    return this.getRequest(AppUrl.DOWNLOAD_CENTRES());
  }

  downloadPayments() {
    return this.getRequest(AppUrl.DOWNLOAD_PAYMENTS());
  }

  downloadCouriers() {
    return this.getRequest(AppUrl.DOWNLOAD_COURIERS());
  }
}
