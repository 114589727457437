import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {AlertService} from '../../../shared/services/alert.service';
import {Location} from '@angular/common';
import {StorageService} from '../../../shared/services/storage.service';
import * as moment from 'moment'
import {NotificationService} from '../../../shared/services/notification.service';

@Component({
  selector: 'app-add-notification',
  templateUrl: './add-notification.component.html',
  styleUrls: ['./add-notification.component.scss']
})
export class AddNotificationComponent implements OnInit {
  addNotificationForm: FormGroup;
  submitted: any = false;
  submitting: any = false;
  inputTypePassword: any = 'password';
  user: any;
  id: any;
  currentYear: any = parseInt(moment(new Date()).format('YYYY'));
  currentMonth: any = parseInt(moment(new Date()).format('MM')) - 1;
  currentDate: any = parseInt(moment(new Date()).format('DD'));
  currentHour: any = parseInt(moment(new Date()).format('HH'));
  currentMinute: any = parseInt(moment(new Date()).format('mm'));
  public currentDateTime = new Date(this.currentYear, this.currentMonth, this.currentDate, this.currentHour, this.currentMinute);


  constructor(private router: Router,
              private fb: FormBuilder,
              private notificationService: NotificationService,
              public activatedRoute: ActivatedRoute,
              private alertService: AlertService,
              private _location: Location) {
  }

  ngOnInit() {
    this.user = StorageService.getItem('self');
    if (this.user.user_type !== 'SUPER_ADMIN') {
      this.router.navigateByUrl('');
    }
    this.id = this.activatedRoute.snapshot.params['id'];
    this.refresh();
  }

  refresh() {
    this.addNotificationForm = this.fb.group({
      'startNotifying': ['', Validators.required],
      'endNotifying': ['', Validators.required],
      'message': ['', Validators.required]
    });

    if (this.id) {
      this.detail(this.id);
    }
  }

  detail(id) {
    this.notificationService.detail(parseInt(id)).subscribe(data => {
      if (data) {
        this.addNotificationForm.patchValue({
          'startNotifying': (data && data.start_date_time) ? new Date(data.start_date_time) : '',
          'endNotifying': (data && data.end_date_time) ? new Date(data.end_date_time) : '',
          'message': (data && data.message) ? data.message : ''
        });
      }
    }, error => {
      this.back();
    });
  }

  back() {
    this._location.back();
  }

  submit() {
    this.submitted = true;
    if (!this.addNotificationForm.valid) {
      return;
    }
    if (this.submitted) {
      const params = {
        'start_date_time': (this.addNotificationForm.value && moment(this.addNotificationForm.value.startNotifying).format('YYYY-MM-DD hh:mm a')) ? moment(this.addNotificationForm.value.startNotifying).format('YYYY-MM-DD hh:mm a') : '',
        'end_date_time': (this.addNotificationForm.value && moment(this.addNotificationForm.value.endNotifying).format('YYYY-MM-DD hh:mm a')) ? moment(this.addNotificationForm.value.endNotifying).format('YYYY-MM-DD hh:mm a') : '',
        'message': (this.addNotificationForm.value && this.addNotificationForm.value.message) ? this.addNotificationForm.value.message : ''
      };
      this.submitting = true;
      this.notificationService.create(params).subscribe(data => {
        if (data) {
          this.submitting = false;
          this.submitted = false;
          this.back();
        }
      }, error => {
        this.submitting = false;
        this.submitted = false;
      });
    }
  }

  update() {
    this.submitted = true;
    if (this.addNotificationForm.value.startNotifying === '' || this.addNotificationForm.value.endNotifying === '' || this.addNotificationForm.value.message === '') {
      return;
    }
    if (this.submitted) {
      const params = {
        'start_date_time': (this.addNotificationForm.value && moment(this.addNotificationForm.value.startNotifying).format('YYYY-MM-DD hh:mm a')) ? moment(this.addNotificationForm.value.startNotifying).format('YYYY-MM-DD hh:mm a') : '',
        'end_date_time': (this.addNotificationForm.value && moment(this.addNotificationForm.value.endNotifying).format('YYYY-MM-DD hh:mm a')) ? moment(this.addNotificationForm.value.endNotifying).format('YYYY-MM-DD hh:mm a') : '',
        'message': (this.addNotificationForm.value && this.addNotificationForm.value.message) ? this.addNotificationForm.value.message : ''
      };
      this.submitting = true;
      this.notificationService.update(this.id, params).subscribe(data => {
        this.submitting = false;
        this.submitted = false;
        this.back();
      }, error => {
        this.submitting = false;
        this.submitted = false;
      });
    }
  }
}
