import {Component, OnInit} from '@angular/core';
import {StatusService} from '../../shared/services/status.service';
import {Router} from '@angular/router';
import {StorageService} from '../../shared/services/storage.service';

@Component({
  selector: 'app-statuses',
  templateUrl: './statuses.component.html',
  styleUrls: ['./statuses.component.scss']
})
export class StatusesComponent implements OnInit {
  statuses = [];
  user: any;
  pagination = {
    page: 1,
    total: null,
    perpage: 15,
    pages: null
  };

  constructor(private statusService: StatusService,
              private router: Router) {
  }

  ngOnInit() {
    this.user = StorageService.getItem('self');
    if (this.user.user_type !== 'SUPER_ADMIN' && this.user.user_type !== 'ADMIN') {
      this.navigateTo('');
    }
    this.get();
  }

  get(params: any = {}) {
    params.page = this.pagination.page;
    params.per_page = this.pagination.perpage;
    this.statuses = [];
    this.statusService.get(params).subscribe(data => {
      if (data && data['data'].length > 0) {
        this.statuses = data['data'];
        this.pagination = {
          page: data.current_page,
          total: data.total,
          perpage: data.per_page,
          pages: data.last_page,
        }
      } else {
        this.statuses = [];
      }
    });
  }

  updateStatus(status, data) {
    const params = {
      'status': status
    };
    this.statusService.updateStatus(data.id, params).subscribe(data => {
      this.get();
    });
  }

  navigateTo(url) {
    this.router.navigateByUrl(url);
  }

  edit(id) {
    const url = 'status/' + id + '/edit';
    this.router.navigateByUrl(url);
  }

  onPageChange(page) {
    this.pagination.page = page;
    this.get();
  }
}
