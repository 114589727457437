import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {CentreService} from '../../shared/services/centre.service';
import {FormBuilder, FormGroup} from '@angular/forms';
import {ExecutiveService} from '../../shared/services/executive.service';
import {StorageService} from '../../shared/services/storage.service';

@Component({
  selector: 'app-couriers-list',
  templateUrl: './couriers-list.component.html',
  styleUrls: ['./couriers-list.component.scss']
})
export class CouriersListComponent implements OnInit {
  couriers = [];
  showSearch = false;
  searchForm: FormGroup;
  executivesData = [];
  user: any;
  submitting: any = false;
  pagination = {
    page: 1,
    total: null,
    perpage: 15,
    pages: null
  };

  constructor(private centreService: CentreService,
              private router: Router,
              private executiveService: ExecutiveService,
              private fb: FormBuilder) {
  }

  ngOnInit() {
    this.user = StorageService.getItem('self');
    this.refresh();
    this.getExecutives();
  }

  refresh() {
    this.searchForm = this.fb.group({
      'date': [''],
      'courierNo': [''],
      'executive_id': [''],
      'type': ['']
    });
    this.get();
  }

  getExecutives() {
    this.executiveService.get({'page': -1}).subscribe(data => {
      if (data && data['data'].length > 0) {
        this.executivesData = data['data'];
      }
    });
  }

  get(params: any = {}) {
    params.page = this.pagination.page;
    params.per_page = this.pagination.perpage;
    this.couriers = [];
    this.centreService.getAllCourier(params).subscribe(data => {
      if (data && data['data'].length > 0) {
        this.couriers = data['data'];
        this.pagination = {
          page: data.current_page,
          total: data.total,
          perpage: data.per_page,
          pages: data.last_page,
        }
      }
    });
  }

  search() {
    let params = {
      'date': (this.searchForm.value && this.searchForm.value.date) ? this.searchForm.value.date : '',
      'courier_no': (this.searchForm.value && this.searchForm.value.courierNo) ? this.searchForm.value.courierNo : '',
      'executive_id': (this.searchForm.value && this.searchForm.value.executive_id) ? this.searchForm.value.executive_id : '',
      'type': (this.searchForm.value && this.searchForm.value.type) ? this.searchForm.value.type : ''
    };
    this.get(params);
  }

  downloadCouriers() {
    this.centreService.downloadCouriers().subscribe(data => {
      if (data) {
        window.open(data['url'], '_blank');
      }
    });
  }

  delete(id) {
    this.centreService.deleteCourier(id).subscribe(data => {
      this.get();
    });
  }

  navigateTo(url) {
    this.router.navigateByUrl(url);
  }

  edit(centreId, id) {
    const url = 'centre/' + centreId + '/courier/' + id + '/edit';
    this.router.navigateByUrl(url);
  }

  onPageChange(page) {
    this.pagination.page = page;
    this.get();
  }
}
