import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {AlertService} from '../../../../shared/services/alert.service';
import {CentreService} from '../../../../shared/services/centre.service';
import {StateService} from '../../../../shared/services/state.service';
import {ExecutiveService} from '../../../../shared/services/executive.service';
import {BsDatepickerConfig} from 'ngx-bootstrap';
import * as moment from 'moment';
import {Location} from '@angular/common';

@Component({
  selector: 'app-add-courier',
  templateUrl: './add-courier.component.html',
  styleUrls: ['./add-courier.component.scss']
})
export class AddCourierComponent implements OnInit {
  addCourierForm: FormGroup;
  submitted: any = false;
  submitting: any = false;
  states: any = [];
  id: any;
  centreId: any;
  executivesData: any;
  courierData: any;
  centreData: any;
  bsConfig: Partial<BsDatepickerConfig>;

  constructor(private router: Router,
              private fb: FormBuilder,
              private centreService: CentreService,
              private stateService: StateService,
              public activatedRoute: ActivatedRoute,
              private alertService: AlertService,
              private execitiveService: ExecutiveService,
              private _location: Location) {
    this.bsConfig = Object.assign({}, {containerClass: 'theme-dark-blue', dateInputFormat: 'YYYY-MM-DD'}, {showWeekNumbers: false});
  }

  ngOnInit() {
    this.centreId = this.activatedRoute.snapshot.params['centre_id'];
    this.id = this.activatedRoute.snapshot.params['id'];
    this.refresh();
  }

  refresh() {
    this.addCourierForm = this.fb.group({
      'date': ['', Validators.required],
      'courierNo': ['', Validators.required],
      'executive_id': ['', Validators.required],
      'type': ['', Validators.required],
      'quantity': ['', Validators.required]
    });
    this.getExecutives();
    if (this.centreId && !this.id) {
      this.centreDetail(this.centreId);
    }
    if (this.centreId && this.id) {
      this.courierDetail(this.id);
    }
  }

  centreDetail(id) {
    this.centreService.detail(parseInt(id)).subscribe(data => {
      if (data) {
        this.centreData = data;
      }
    }, error => {
      this.backToCentres();
    });
  }

  courierDetail(id) {
    this.centreService.courierDetail(parseInt(id)).subscribe(data => {
      if (data) {
        this.courierData = data;
        this.addCourierForm.patchValue({
          'date': (data && data.date) ? moment(data.date).format('YYYY-MM-DD') : '',
          'courierNo': (data && data.courier_no) ? data.courier_no : '',
          'executive_id': (data && data.executive_id) ? data.executive_id : '',
          'type': (data && data.type) ? data.type : '',
          'quantity': (data && data.quantity) ? data.quantity : ''
        });
      }
    }, error => {
      this.back();
    });
  }

  getExecutives() {
    this.execitiveService.get({'page': -1}).subscribe(data => {
      if (data && data['data'].length > 0) {
        this.executivesData = data['data'];
      }
    });
  }

  back() {
    // this.router.navigateByUrl('centre/' + this.centreId + '/couriers');
    this._location.back();
  }

  backToCentres() {
    // this.router.navigateByUrl('centre/' + this.id + '/couriers');
    this._location.back();
  }

  submit() {
    this.submitted = true;
    if (!this.addCourierForm.valid) {
      return;
    }
    if (this.submitted) {
      const params = {
        'date': (this.addCourierForm.value && this.addCourierForm.value.date) ? moment(this.addCourierForm.value.date).format('YYYY-MM-DD') : '',
        'courier_no': (this.addCourierForm.value && this.addCourierForm.value.courierNo) ? this.addCourierForm.value.courierNo : '',
        'executive_id': (this.addCourierForm.value && this.addCourierForm.value.executive_id) ? this.addCourierForm.value.executive_id : '',
        'type': (this.addCourierForm.value && this.addCourierForm.value.type) ? this.addCourierForm.value.type : '',
        'quantity': (this.addCourierForm.value && this.addCourierForm.value.quantity) ? this.addCourierForm.value.quantity : ''
      };
      this.submitting = true;
      this.centreService.createCourier(this.centreId, params).subscribe(data => {
        if (data) {
          this.submitting = false;
          this.submitted = false;
          this.back();
        }
      }, error => {
        this.submitting = false;
        this.submitted = false;
        if (error && error['error'] && error['error']['errors'] && error['error'].hasOwnProperty('errors')) {
          if (error['error']['errors'].courier_no && error['error']['errors'].courier_no.length > 0) {
            this.alertService.showErrors(error['error']['errors'].courier_no[0]);
            return;
          }
        }
      });
    }
  }

  update() {
    this.submitted = true;
    if (!this.addCourierForm.valid) {
      return;
    }
    if (this.submitted) {
      const params = {
        'date': (this.addCourierForm.value && this.addCourierForm.value.date) ? moment(this.addCourierForm.value.date).format('YYYY-MM-DD') : '',
        'courier_no': (this.addCourierForm.value && this.addCourierForm.value.courierNo) ? this.addCourierForm.value.courierNo : '',
        'executive_id': (this.addCourierForm.value && this.addCourierForm.value.executive_id) ? this.addCourierForm.value.executive_id : '',
        'type': (this.addCourierForm.value && this.addCourierForm.value.type) ? this.addCourierForm.value.type : '',
        'quantity': (this.addCourierForm.value && this.addCourierForm.value.quantity) ? this.addCourierForm.value.quantity : ''
      };
      this.submitting = true;
      this.centreService.updateCourier(this.id, params).subscribe(data => {
        this.submitting = false;
        this.submitted = false;
        this.back();
      }, error => {
        this.submitting = false;
        this.submitted = false;
        if (error && error['error'] && error['error']['errors'] && error['error'].hasOwnProperty('errors')) {
          if (error['error']['errors'].courier_no && error['error']['errors'].courier_no.length > 0) {
            this.alertService.showErrors(error['error']['errors'].courier_no[0]);
            return;
          }
        }
      });
    }
  }
}
