import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {BaseService} from './base.service';
import {Observable} from 'rxjs';
import {AlertService} from './alert.service';
import {GlobalService} from './global.service';
import {AppUrl} from '../constants/app-url';

@Injectable({
  providedIn: 'root'
})
export class IssueService extends BaseService {
  constructor(public http: HttpClient,
              public alertService: AlertService,
              public globalService: GlobalService) {
    super(http, alertService, globalService);
  }

  create(centreId, data): Observable<any> {
    return this.postRequest(AppUrl.ISSUES(centreId), data);
  }

  get(centreId, data): Observable<any> {
    return this.getRequest(AppUrl.ISSUES(centreId), data);
  }

  count(): Observable<any> {
    return this.getRequest(AppUrl.ISSUES_COUNT());
  }

  pendingCount(): Observable<any> {
    return this.getRequest(AppUrl.ISSUES_PENDING_COUNT());
  }

  inProgressCount(): Observable<any> {
    return this.getRequest(AppUrl.ISSUES_IN_PROGRESS_COUNT());
  }

  resolvedCount(): Observable<any> {
    return this.getRequest(AppUrl.ISSUES_RESOLVED_COUNT());
  }

  updation(issueId, centreId, data): Observable<any> {
    return this.putRequest(AppUrl.ISSUE_UPDATION(issueId, centreId), data);
  }

  delete(id): Observable<any> {
    return this.deleteRequest(AppUrl.DELETE_ISSUE(id));
  }

  issuesAddedByUserCount(id?): Observable<any> {
    if (id) {
      return this.getRequest(AppUrl.ISSUES_ADDED_BY_USER_COUNT(id));
    } else {
      return this.getRequest(AppUrl.ISSUES_ADDED_BY_USER_COUNT());
    }
  }

  issuesInProgressByUserCount(id?): Observable<any> {
    if (id) {
      return this.getRequest(AppUrl.ISSUES_IN_PROGRESS_BY_USER_COUNT(id));
    } else {
      return this.getRequest(AppUrl.ISSUES_IN_PROGRESS_BY_USER_COUNT());
    }
  }

  issuesResolvedByUserCount(id?): Observable<any> {
    if (id) {
      return this.getRequest(AppUrl.ISSUES_RESOLVED_BY_USER_COUNT(id));
    } else {
      return this.getRequest(AppUrl.ISSUES_RESOLVED_BY_USER_COUNT());
    }
  }

  downloadIssues() {
    return this.getRequest(AppUrl.DOWNLOAD_ISSUES());
  }
}
