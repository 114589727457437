import {Component} from '@angular/core';
import {SnotifyService} from 'ng-snotify';
import {GlobalService} from './shared/services/global.service';
import {AuthService} from './shared/services/auth.service';
import {Router} from '@angular/router';
import {StorageService} from './shared/services/storage.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent {
  constructor(private snotifyService: SnotifyService,
              private globalService: GlobalService,
              private authService: AuthService,
              private router: Router) {
    this.checkLogin();

    this.globalService.reloadSelf.subscribe((d) => {
      if (d) {
        this.reloadSelf();
        this.globalService.reloadSelf.next(false);
      }
    })
  }

  checkLogin() {
    if (StorageService.getItem('accessToken') && StorageService.getItem('self')) {
      this.globalService.setAccessToken(StorageService.getItem('accessToken'));
      this.globalService.setSelf(StorageService.getItem('self'));
      this.reloadSelf();
      // this.metaService.refreshMeta();
      // this.router.navigateByUrl('');
    } else {
      this.router.navigateByUrl('login');
    }
  }

  reloadSelf() {
    this.authService.self({all: true}).subscribe((data) => {
      // this.globalService.setAccessToken(data.token);
      this.globalService.setSelf({'name': data.name, 'userId': data.id, 'email': data.email, 'user_type': data.user_type});
      // this.globalService.setSelf(userDetails);
      return;
    }, e => {
      StorageService.clearAll();
      this.router.navigateByUrl('/login');
      /*this.authService.logout().subscribe(data => {
        this.router.navigateByUrl('');
      });*/
    });
  }
}
