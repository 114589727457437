import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {StatusService} from '../../../shared/services/status.service';
import {AlertService} from '../../../shared/services/alert.service';
import {Location} from '@angular/common';

@Component({
  selector: 'app-add-status',
  templateUrl: './add-status.component.html',
  styleUrls: ['./add-status.component.scss']
})
export class AddStatusComponent implements OnInit {
  addStatusForm: FormGroup;
  submitted: any = false;
  submitting: any = false;
  color: any;
  id: any;
  textColors: any = [
    {
      'name': 'Light',
      'value': 'LIGHT'
    },
    {
      'name': 'Dark',
      'value': 'DARK'
    }
  ];

  constructor(private router: Router,
              private fb: FormBuilder,
              private statusService: StatusService,
              private alertService: AlertService,
              public activatedRoute: ActivatedRoute,
              private _location: Location) {
  }

  ngOnInit() {
    this.id = this.activatedRoute.snapshot.params['id'];
    this.refresh();
  }

  refresh() {
    this.addStatusForm = this.fb.group({
      'name': ['', Validators.required],
      'color': [''],
      'text': ['', Validators.required],
    });
    if (this.id) {
      this.detail(this.id);
    }
  }

  back() {
    // this.router.navigateByUrl('statuses');
    this._location.back();
  }

  detail(id) {
    this.statusService.detail(parseInt(id)).subscribe(data => {
      if (data) {
        this.color = data.color;
        this.addStatusForm.patchValue({
          'name': (data && data.name) ? data.name : '',
          'text': (data && data.text) ? data.text : ''
        });
      }
    }, error => {
      this.back();
    });
  }

  submit() {
    this.submitted = true;
    if (!this.addStatusForm.valid) {
      return;
    }
    if (this.submitted) {
      const params = {
        'name': (this.addStatusForm.value && this.addStatusForm.value.name) ? this.addStatusForm.value.name : '',
        'color': this.color,
        'text': (this.addStatusForm.value && this.addStatusForm.value.text) ? this.addStatusForm.value.text : ''
      };
      this.submitting = true;
      this.statusService.create(params).subscribe(data => {
        if (data) {
          this.submitting = false;
          this.submitted = false;
          this.back();
        }
      }, error => {
        this.submitting = false;
        this.submitted = false;
        if (error && error['error'] && error['error']['errors'] && error['error'].hasOwnProperty('errors')) {
          if (error['error']['errors'].name && error['error']['errors'].name.length > 0) {
            this.alertService.showErrors(error['error']['errors'].name[0]);
            return;
          }
          if (error['error']['errors'].color && error['error']['errors'].color.length > 0) {
            this.alertService.showErrors(error['error']['errors'].color[0]);
            return;
          }
        }
      });
    }
  }

  update() {
    this.submitted = true;
    if (!this.addStatusForm.valid) {
      return;
    }
    if (this.submitted) {
      const params = {
        'name': (this.addStatusForm.value && this.addStatusForm.value.name) ? this.addStatusForm.value.name : '',
        'color': this.color,
        'text': (this.addStatusForm.value && this.addStatusForm.value.text) ? this.addStatusForm.value.text : ''
      };
      this.submitting = true;
      this.statusService.update(this.id, params).subscribe(data => {
        this.submitting = false;
        this.submitted = false;
        this.back();
      }, error => {
        this.submitting = false;
        this.submitted = false;
        if (error && error['error'] && error['error']['errors'] && error['error'].hasOwnProperty('errors')) {
          if (error['error']['errors'].name && error['error']['errors'].name.length > 0) {
            this.alertService.showErrors(error['error']['errors'].name[0]);
            return;
          }
          if (error['error']['errors'].color && error['error']['errors'].color.length > 0) {
            this.alertService.showErrors(error['error']['errors'].color[0]);
            return;
          }
        }
      });
    }
  }
}
