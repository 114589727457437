import {Component, OnInit} from '@angular/core';
import {CentreService} from '../../shared/services/centre.service';
import {Router} from '@angular/router';
import {FormBuilder, FormGroup} from '@angular/forms';
import {StateService} from '../../shared/services/state.service';
import {ExecutiveService} from '../../shared/services/executive.service';
import {StorageService} from '../../shared/services/storage.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {AlertService} from '../../shared/services/alert.service';
import {IssueService} from '../../shared/services/issue.service';
import {Location} from "@angular/common";

@Component({
  selector: 'app-centres',
  templateUrl: './centres.component.html',
  styleUrls: ['./centres.component.scss']
})

export class CentresComponent implements OnInit {
  centres = [];
  showSearch = false;
  searchForm: FormGroup;
  submitting = false;
  states = [];
  executives = [];
  user: any;
  selectedLabel: any;
  pagination = {
    page: 1,
    total: null,
    perpage: 15,
    pages: null
  };

  constructor(private centreService: CentreService,
              private router: Router,
              private fb: FormBuilder,
              private stateService: StateService,
              private executiveService: ExecutiveService,
              public modalService: NgbModal,
              public issueService: IssueService,
              public alertService: AlertService,
              private _location: Location) {
  }

  ngOnInit() {
    this.user = StorageService.getItem('self');
    this.refresh();
  }

  refresh() {
    this.searchForm = this.fb.group({
      'name': [''],
      'code': [''],
      'contact': [''],
      'executive_id': [''],
      'state_id': ['']
    });
    this.getStates();
    this.getExecutives();
    this.get();
  }

  get(params: any = {}) {
    params.page = this.pagination.page;
    params.per_page = this.pagination.perpage;
    this.centres = [];
    this.submitting = true;
    this.centreService.get(params).subscribe(data => {
      if (data && data['data'].length > 0) {
        this.centres = data['data'];
        if (this.centres && this.centres.length > 0) {
          this.centres.forEach(centre => {
            if (centre) {
              centre['totalIssues'] = (centre['issues'] && centre['issues'].length ? centre['issues'].length : 0);
              centre['totalCouriers'] = (centre['couriers'] && centre['couriers'].length ? centre['couriers'].length : 0);
              let i = 0;
              if (centre['issues'] && centre['issues'].length > 0) {
                centre['issues'].forEach(issue => {
                  if (issue['status'].id === 1) {
                    i++;
                  }
                })
              }
              centre['pendingIssues'] = i;
            }
          });
        }
        this.pagination = {
          page: data.current_page,
          total: data.total,
          perpage: data.per_page,
          pages: data.last_page,
        };
      } else {
        this.centres = [];
      }
      this.submitting = false;
    }, error => {
      this.submitting = false;
    });
  }

  calcSum(a, b) {
    return (parseFloat(a) ? parseFloat(a) : 0) + (parseFloat(b) ? parseFloat(b) : 0);
  }

  search() {
    let params = {
      'name': (this.searchForm.value && this.searchForm.value.name) ? this.searchForm.value.name : '',
      'code': (this.searchForm.value && this.searchForm.value.code) ? this.searchForm.value.code : '',
      'contact': (this.searchForm.value && this.searchForm.value.contact) ? this.searchForm.value.contact : '',
      'executive_id': (this.searchForm.value && this.searchForm.value.executive_id) ? this.searchForm.value.executive_id : '',
      'state_id': (this.searchForm.value && this.searchForm.value.state_id) ? this.searchForm.value.state_id : ''
    };
    this.get(params);
  }

  getStates() {
    this.stateService.get().subscribe(data => {
      if (data && data['data'].length > 0) {
        this.states = data['data'];
      }
    });
  }

  getExecutives() {
    this.executiveService.get({'page': -1}).subscribe(data => {
      if (data && data['data'].length > 0) {
        if (this.user.user_type === 'SUPER_ADMIN' || this.user.user_type === 'ADMIN') {
          this.executives = data['data'];
        } else {
          this.executives = [{
            'id': this.user.userId,
            'name': this.user.name
          }];
        }
      }
    });
  }

  downloadCentres() {
    this.centreService.downloadCentres().subscribe(data => {
      if (data) {
        window.open(data['url'], '_blank');
      }
    });
  }

  downloadIssues() {
    this.issueService.downloadIssues().subscribe(data => {
      if (data) {
        window.open(data['url'], '_blank');
      }
    });
  }

  downloadPayments() {
    this.centreService.downloadPayments().subscribe(data => {
      if (data) {
        window.open(data['url'], '_blank');
      }
    });
  }

  navigate(type, id) {
    let url: any;
    if (type === 'COURIER') {
      url = 'centre/' + id + '/couriers';
    } else if (type === 'ISSUES') {
      url = 'centre/' + id + '/issues';
    } else {
      url = 'centre/' + id + '/payment';
    }
    this.router.navigateByUrl(url);
  }

  printLabelModal(content, centre) {
    this.selectedLabel = centre;
    this.modalService.open(content, {'size': 'lg'});
  }

  navigateTo(url) {
    this.router.navigateByUrl(url);
  }

  edit(id) {
    const url = 'centre/' + id + '/edit';
    this.router.navigateByUrl(url);
  }

  back() {
    //this.router.navigateByUrl('centres');
    this._location.back();
  }

  delete(id) {
    this.centreService.delete(id).subscribe(data => {
      this.get();
    });
  }

  onPageChange(page) {
    this.pagination.page = page;
    let params = {
      'name': (this.searchForm.value && this.searchForm.value.name) ? this.searchForm.value.name : '',
      'code': (this.searchForm.value && this.searchForm.value.code) ? this.searchForm.value.code : '',
      'contact': (this.searchForm.value && this.searchForm.value.contact) ? this.searchForm.value.contact : '',
      'executive_id': (this.searchForm.value && this.searchForm.value.executive_id) ? this.searchForm.value.executive_id : '',
      'state_id': (this.searchForm.value && this.searchForm.value.state_id) ? this.searchForm.value.state_id : ''
    };
    this.get(params);
  }
}
