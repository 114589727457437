import {environment} from '../../../environments/environment';
import {Injectable} from '@angular/core';

@Injectable()
export class AppUrl {

  static get API_URL(): string {
    return environment.appUrl + 'api/';
  }

  static get APP_URL(): string {
    return environment.appUrl + 'admin/';
  }

  static get LOGIN(): string {
    return AppUrl.API_URL + 'login';
  }

  static get SELF(): string {
    return AppUrl.API_URL + 'self';
  }

  static LOGOUT(): string {
    return AppUrl.API_URL + 'logout';
  }

  static get VERIFY_USER(): string {
    return AppUrl.APP_URL + 'verify-user';
  }

  static EXECUTIVES(id?): string {
    if (id) {
      return AppUrl.APP_URL + 'executive/' + id;
    } else {
      return AppUrl.APP_URL + 'executive';
    }
  }

  static EXECUTIVE_PASSWORD(id?): string {
    if (id) {
      return AppUrl.APP_URL + 'executive-password/' + id;
    }
  }

  static EXECUTIVE_STATUS(id?): string {
    if (id) {
      return AppUrl.APP_URL + 'executive-status/' + id;
    }
  }

  static EXECUTIVES_COUNT(): string {
    return AppUrl.APP_URL + 'executives-count';
  }

  static ADMINS(id?): string {
    if (id) {
      return AppUrl.APP_URL + 'admin/' + id;
    } else {
      return AppUrl.APP_URL + 'admin';
    }
  }

  static ADMIN_PASSWORD(id?): string {
    if (id) {
      return AppUrl.APP_URL + 'admin-password/' + id;
    }
  }

  static ADMIN_STATUS(id?): string {
    if (id) {
      return AppUrl.APP_URL + 'admin-status/' + id;
    }
  }

  static ADMINS_COUNT(): string {
    return AppUrl.APP_URL + 'admins-count';
  }

  static COURIER_EXECUTIVES(id?): string {
    if (id) {
      return AppUrl.APP_URL + 'courier-executive/' + id;
    } else {
      return AppUrl.APP_URL + 'courier-executive';
    }
  }

  static COURIER_EXECUTIVE_PASSWORD(id?): string {
    if (id) {
      return AppUrl.APP_URL + 'courier-executive-password/' + id;
    }
  }

  static COURIER_EXECUTIVE_STATUS(id?): string {
    if (id) {
      return AppUrl.APP_URL + 'courier-executive-status/' + id;
    }
  }

  static COURIER_EXECUTIVES_COUNT(): string {
    return AppUrl.APP_URL + 'courier-executives-count';
  }

  static UPDATE_USER_PASSWORD(): string {
    return AppUrl.API_URL + 'update-user-password';
  }

  static CENTRES(id?): string {
    if (id) {
      return AppUrl.API_URL + 'centre/' + id;
    } else {
      return AppUrl.API_URL + 'centre';
    }
  }

  static DELETE_CENTER(id?): string {
      return AppUrl.API_URL + 'centre/' + id;
  }

  static DOWNLOAD_CENTRES(): string {
    return AppUrl.APP_URL + 'centres/download';
  }

  static DOWNLOAD_PAYMENTS(): string {
    return AppUrl.APP_URL + 'centres/download-payment';
  }

  static DOWNLOAD_ISSUES(): string {
    return AppUrl.APP_URL + 'issues/download';
  }

  static DOWNLOAD_COURIERS(): string {
    return AppUrl.APP_URL + 'couriers/download';
  }

  static COURIERS(id?): string {
    if (id) {
      return AppUrl.API_URL + 'courier/' + id + '/add';
    }
  }

  static GET_COURIERS(id?): string {
    if (id) {
      return AppUrl.API_URL + 'courier/' + id;
    }
  }

  static GET_ALL_COURIERS(): string {
    return AppUrl.API_URL + 'couriers';
  }

  static DETAILS_COURIER(id?): string {
    if (id) {
      return AppUrl.API_URL + 'courier/' + id + '/detail';
    }
  }

  static UPDATE_COURIERS(id?): string {
    if (id) {
      return AppUrl.API_URL + 'courier/' + id + '/update';
    }
  }

  static DELETE_COURIER(id?): string {
    if (id) {
      return AppUrl.APP_URL + 'courier/' + id;
    }
  }

  static CENTRE_PAYMENT(id?): string {
    if (id) {
      return AppUrl.API_URL + 'centre-payment/' + id;
    }
  }

  static CENTRES_COUNT(): string {
    return AppUrl.API_URL + 'centres-count';
  }

  static COURIERS_COUNT(): string {
    return AppUrl.API_URL + 'couriers-count';
  }

  static CENTRES_ADDED_BY_USER(id?): string {
    if (id) {
      return AppUrl.API_URL + 'centres-added-by-user-count/' + id;
    } else {
      return AppUrl.API_URL + 'centres-added-by-user-count';
    }
  }

  static STATES(): string {
    return AppUrl.APP_URL + 'states';
  }

  static STATUSES(id?): string {
    if (id) {
      return AppUrl.APP_URL + 'status/' + id;
    } else {
      return AppUrl.APP_URL + 'status';
    }
  }

  static STATUS_STATUS(id?): string {
    if (id) {
      return AppUrl.APP_URL + 'status-status/' + id;
    }
  }

  static LABELS(id?): string {
    if (id) {
      return AppUrl.APP_URL + 'label/' + id;
    } else {
      return AppUrl.APP_URL + 'label';
    }
  }

  static LABEL_STATUS(id?): string {
    if (id) {
      return AppUrl.APP_URL + 'label-status/' + id;
    }
  }

  static ISSUES(centreId): string {
    return AppUrl.API_URL + `${centreId}/issue`;
  }

  static ISSUES_COUNT(): string {
    return AppUrl.API_URL + 'issues-count';
  }

  static DELETE_ISSUE(id): string {
    return AppUrl.APP_URL + `issue/` + id;
  }

  static ISSUES_PENDING_COUNT(): string {
    return AppUrl.API_URL + 'pending-issues-count';
  }

  static ISSUES_ADDED_BY_USER_COUNT(id?): string {
    if (id) {
      return AppUrl.API_URL + 'issues-added-by-user-count/' + id;
    } else {
      return AppUrl.API_URL + 'issues-added-by-user-count';
    }
  }

  static ISSUES_IN_PROGRESS_BY_USER_COUNT(id?): string {
    if (id) {
      return AppUrl.API_URL + 'issues-in-progress-by-user-count/' + id;
    } else {
      return AppUrl.API_URL + 'issues-in-progress-by-user-count';
    }
  }

  static ISSUES_IN_PROGRESS_COUNT(): string {
    return AppUrl.API_URL + 'in-progress-issues-count';
  }

  static ISSUES_RESOLVED_COUNT(): string {
    return AppUrl.API_URL + 'resolved-issues-count';
  }

  static ISSUES_RESOLVED_BY_USER_COUNT(id?): string {
    if (id) {
      return AppUrl.API_URL + 'issues-resolved-by-user-count/' + id;
    } else {
      return AppUrl.API_URL + 'issues-resolved-by-user-count';
    }
  }

  static ISSUE_UPDATION(issueId, centreId): string {
    return AppUrl.API_URL + `${centreId}/issue/${issueId}/updation`;
  }

  static SELF_ACTIVITY_LOGS(): string {
    return AppUrl.API_URL + 'self-activities';
  }

  static ALL_ACTIVITY_LOGS(id?): string {
    if (id) {
      return AppUrl.APP_URL + 'all-activities/' + id;
    } else {
      return AppUrl.APP_URL + 'all-activities';
    }
  }

  static SUBJECTS(id?): string {
    if (id) {
      return AppUrl.APP_URL + 'subject/' + id;
    } else {
      return AppUrl.APP_URL + 'subject';
    }
  }

  static SUBJECT_STATUS(id?): string {
    if (id) {
      return AppUrl.APP_URL + 'subject-status/' + id;
    }
  }

  static CONVERSATIONS(id?): string {
    if (id) {
      return AppUrl.API_URL + 'conversation/' + id;
    } else {
      return AppUrl.API_URL + 'conversation';
    }
  }

  static EXECUTIVE_CONVERSATIONS(id?): string {
    if (id) {
      return AppUrl.APP_URL + 'conversation/' + id;
    }
  }

  static NOTIFICATIONS(id?): string {
    if (id) {
      return AppUrl.APP_URL + 'notification/' + id;
    } else {
      return AppUrl.APP_URL + 'notification';
    }
  }
}
