import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Router} from '@angular/router';
import {GlobalService} from '../../shared/services/global.service';
import {AuthService} from '../../shared/services/auth.service';
import {StorageService} from '../../shared/services/storage.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  loginUserForm: FormGroup;
  isBusy = false;
  loginPressed = false;

  constructor(private router: Router,
              private fb: FormBuilder,
              private globalService: GlobalService,
              private authService: AuthService) {
  }

  ngOnInit() {
    if (this.checkLoggedInStatus()) {
      this.setSelfData();
      this.router.navigateByUrl('');
    }
    this.refresh();
  }

  refresh() {
    this.loginUserForm = this.fb.group({
      'username': ['', Validators.required],
      'password': ['', Validators.required]
    });
  }

  loginUser() {
    if (this.isBusy) {
      return;
    }
    this.isBusy = true;
    this.loginPressed = true;
    if (this.checkLoggedInStatus()) {
      this.setSelfData();
      this.router.navigateByUrl('');
    } else {
      this.authService.login(this.loginUserForm.getRawValue()).subscribe(data => {
        if (data.id) {
          this.globalService.setAccessToken(data.token);
          this.globalService.setSelf({'name': data.name, 'userId': data.id, 'username': data.username, 'user_type': data.user_type});
          this.router.navigateByUrl('');
          this.loginUserForm.reset();
          this.loginPressed = false;
        }
      }, error => {
        this.isBusy = false;
        this.loginPressed = false;
      });
    }
  }

  checkLoggedInStatus() {
    const accessToken = StorageService.getItem('accessToken');
    return accessToken && accessToken !== 'null';
  }

  setSelfData() {
    this.authService.self({all: true}).subscribe((data) => {
      this.isBusy = false;
      this.globalService.setSelf({'name': data.name, 'userId': data.id, 'username': data.username, 'user_type': data.user_type});
      return;
    }, e => {
      this.isBusy = false;
      StorageService.clearAll();
    });
  }
}
