import {Component, OnInit} from '@angular/core';
import {IssueService} from 'app/shared/services/issue.service';
import {ActivatedRoute, Router} from '@angular/router';
import {CentreService} from '../../shared/services/centre.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {StatusService} from '../../shared/services/status.service';
import {LabelService} from '../../shared/services/label.service';
import {FormBuilder, FormGroup} from '@angular/forms';
import {StorageService} from '../../shared/services/storage.service';
import {Location} from '@angular/common';

@Component({
  selector: 'app-issues',
  templateUrl: './issues.component.html',
  styleUrls: ['./issues.component.scss']
})
export class IssuesComponent implements OnInit {
  issues = [];
  centre: any;
  statuses = [];
  labels = [];
  selectedStatus: any;
  selectedLabel: any;
  selectedUpdations: any;
  submitting: any = false;
  showSearch = false;
  user: any;
  searchForm: FormGroup;
  pagination = {
    page: 1,
    total: null,
    perpage: 15,
    pages: null
  };

  constructor(private issueService: IssueService,
              private centreService: CentreService,
              private activatedRoute: ActivatedRoute,
              public modalService: NgbModal,
              private statusService: StatusService,
              private labelService: LabelService,
              private router: Router,
              private fb: FormBuilder,
              private _location: Location) {
  }

  ngOnInit() {
    this.user = StorageService.getItem('self');
    this.refresh();
  }

  refresh() {
    this.searchForm = this.fb.group({
      'subject': [''],
      'description': [''],
      'label_id': [''],
      'status_id': [''],
    });
    this.detail();
    this.getStatuses();
    this.getLabels();
  }

  detail() {
    const id = parseInt(this.activatedRoute.snapshot.params['id']);
    this.centreService.detail(id).subscribe(data => {
      if (data) {
        this.centre = data;
        this.get();
      }
    }, error => {
      this.back();
    });
  }

  get(params: any = {}) {
    params.page = this.pagination.page;
    params.per_page = this.pagination.perpage;
    params.per_page = this.pagination.perpage;
    this.submitting = true;
    this.issues = [];
    this.issueService.get(this.centre.id, params).subscribe(data => {
      if (data && data['data'].length > 0) {
        this.issues = data['data'];
        this.pagination = {
          page: data.current_page,
          total: data.total,
          perpage: data.per_page,
          pages: data.last_page,
        };
      } else {
        this.issues = [];
      }
      this.submitting = false;
    }, error => {
      this.submitting = false;
    });
  }

  getStatuses() {
    this.statusService.get({'page': -1}).subscribe(data => {
      if (data && data['data'].length > 0) {
        this.statuses = data['data'];
      }
    });
  }

  getLabels() {
    this.labelService.get({'page': -1}).subscribe(data => {
      if (data && data['data'].length > 0) {
        this.labels = data['data'];
      }
    });
  }

  statusModal(content, data) {
    this.selectedLabel = undefined;
    this.selectedUpdations = undefined;
    this.selectedStatus = data;
    if (this.statuses && this.statuses.length > 0) {
      this.statuses.forEach(status => {
        status['isSelected'] = parseInt(status.id) === parseInt(data['status_id']);
      });
      this.modalService.open(content, {'size': 'sm'});
    }
  }

  labelModal(content, data) {
    this.selectedStatus = undefined;
    this.selectedUpdations = undefined;
    this.selectedLabel = data;
    if (this.labels && this.labels.length > 0) {
      this.labels.forEach(label => {
        label['isSelected'] = parseInt(label.id) === parseInt(data['label_id']);
      });
      this.modalService.open(content, {'size': 'sm'});
    }
  }

  updationsModal(content, data) {
    this.selectedLabel = undefined;
    this.selectedStatus = undefined;
    this.selectedUpdations = data;
    this.modalService.open(content, {'size': 'xl'});
  }

  assignStatus(data) {
    if (this.statuses && this.statuses.length > 0) {
      this.statuses.forEach(status => {
        status['isSelected'] = parseInt(status.id) === parseInt(data.id);
      })
    }
  }

  assignLabel(data) {
    if (this.labels && this.labels.length > 0) {
      this.labels.forEach(label => {
        label['isSelected'] = parseInt(label.id) === parseInt(data.id);
      })
    }
  }

  updateStatus() {
    if (this.statuses && this.statuses.length > 0) {
      let selectedStatusId: any;
      this.statuses.forEach(status => {
        if (status['isSelected']) {
          selectedStatusId = status.id
        }
      });
      this.submitting = true;
      if (selectedStatusId && this.selectedStatus && this.centre) {
        this.issueService.updation(this.selectedStatus.id, this.centre.id, {'status_id': selectedStatusId}).subscribe(data => {
          this.modalService.dismissAll('');
          this.get();
        });
      }
    }
  }

  updateLabel() {
    if (this.labels && this.labels.length > 0) {
      let selectedLabelId: any;
      this.labels.forEach(label => {
        if (label['isSelected']) {
          selectedLabelId = label.id
        }
      });
      this.submitting = true;
      if (selectedLabelId && this.selectedLabel && this.centre) {
        this.issueService.updation(this.selectedLabel.id, this.centre.id, {'label_id': selectedLabelId}).subscribe(data => {
          this.modalService.dismissAll('');
          this.get();
        });
      }
    }
  }

  search() {
    let params = {
      'subject': (this.searchForm.value && this.searchForm.value.subject) ? this.searchForm.value.subject : '',
      'description': (this.searchForm.value && this.searchForm.value.description) ? this.searchForm.value.description : '',
      'label_id': (this.searchForm.value && this.searchForm.value.label_id) ? this.searchForm.value.label_id : '',
      'status_id': (this.searchForm.value && this.searchForm.value.status_id) ? this.searchForm.value.status_id : ''
    };
    this.get(params);
  }

  delete(id) {
    this.issueService.delete(id).subscribe(data => {
      this.get();
    });
  }

  navigateToAddIssue() {
    this.router.navigateByUrl('issues/' + this.centre.id + '/add');
  }

  onPageChange(page) {
    this.pagination.page = page;
    this.get();
  }

  back() {
    // this.router.navigateByUrl('centres');
    this._location.back();
  }
}
