import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {Router} from '@angular/router';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {CourierExecutiveService} from '../../shared/services/courier-executive.service';
import {StorageService} from '../../shared/services/storage.service';

@Component({
  selector: 'app-courier-executives',
  templateUrl: './courier-executives.component.html',
  styleUrls: ['./courier-executives.component.scss']
})
export class CourierExecutivesComponent implements OnInit {
  courierExecutives = [];
  searchForm: FormGroup;
  showSearch = false;
  submitted: any = false;
  submitting: any = false;
  updatePasswordForm: FormGroup;
  inputTypePassword = 'password';
  id: any;
  user: any;
  pagination = {
    page: 1,
    total: null,
    perpage: 15,
    pages: null
  };

  constructor(private courierExecutiveService: CourierExecutiveService,
              private router: Router,
              private fb: FormBuilder,
              public modalService: NgbModal) {
  }

  ngOnInit() {
    this.user = StorageService.getItem('self');
    if (this.user.user_type !== 'SUPER_ADMIN' && this.user.user_type !== 'ADMIN') {
      this.router.navigateByUrl('');
    }
    this.refresh();
  }

  refresh() {
    this.searchForm = this.fb.group({
      'name': [''],
      'username': [''],
      'email': [''],
      'contact': [''],
    });
    this.updatePasswordForm = this.fb.group({
      'password': ['']
    });
    this.get();
  }

  get(params: any = {}) {
    params.page = this.pagination.page;
    params.per_page = this.pagination.perpage;
    this.submitting = true;
    this.courierExecutives = [];
    this.courierExecutiveService.get(params).subscribe(data => {
      if (data && data['data'].length > 0) {
        this.courierExecutives = data['data'];
        this.pagination = {
          page: data.current_page,
          total: data.total,
          perpage: data.per_page,
          pages: data.last_page,
        };
      } else {
        this.courierExecutives = [];
      }
      this.submitting = false;
    }, error => {
      this.submitting = false;
    });
  }

  search() {
    let params = {
      'name': (this.searchForm.value && this.searchForm.value.name) ? this.searchForm.value.name : '',
      'username': (this.searchForm.value && this.searchForm.value.username) ? this.searchForm.value.username : '',
      'email': (this.searchForm.value && this.searchForm.value.email) ? this.searchForm.value.email : '',
      'contact': (this.searchForm.value && this.searchForm.value.contact) ? this.searchForm.value.contact : ''
    };
    this.get(params);
  }

  navigateTo(url) {
    this.router.navigateByUrl(url);
  }

  edit(id) {
    const url = 'courier-executive/' + id + '/edit';
    this.router.navigateByUrl(url);
  }

  updatePasswordModal(content, id) {
    this.id = id;
    this.modalService.open(content, {'size': 'sm'});
  }

  updatePassword() {
    this.submitted = true;
    if (!this.updatePasswordForm.valid) {
      return;
    }
    if (this.submitted) {
      const params = {
        'password': (this.updatePasswordForm.value && this.updatePasswordForm.value.password) ? this.updatePasswordForm.value.password : ''
      };
      this.submitting = true;
      this.courierExecutiveService.updatePassword(this.id, params).subscribe(data => {
        this.submitting = false;
        this.submitted = false;
        this.id = undefined;
        this.updatePasswordForm.patchValue({
          'password': '',
        });
        this.modalService.dismissAll('');
      }, error => {
        this.submitting = false;
        this.submitted = false;
        this.id = undefined;
      });
    }
  }

  updateStatus(status, data) {
    this.submitted = true;
    if (this.submitted) {
      const params = {
        'status': status
      };
      this.submitting = true;
      this.courierExecutiveService.updateStatus(data.id, params).subscribe(data => {
        this.submitting = false;
        this.submitted = false;
        this.id = undefined;
        this.modalService.dismissAll('');
        this.get();
      }, error => {
        this.submitting = false;
        this.submitted = false;
        this.id = undefined;
      });
    }
  }

  onPageChange(page) {
    this.pagination.page = page;
    let params = {
      'name': (this.searchForm.value && this.searchForm.value.name) ? this.searchForm.value.name : '',
      'username': (this.searchForm.value && this.searchForm.value.username) ? this.searchForm.value.username : '',
      'email': (this.searchForm.value && this.searchForm.value.email) ? this.searchForm.value.email : '',
      'contact': (this.searchForm.value && this.searchForm.value.contact) ? this.searchForm.value.contact : ''
    };
    this.get(params);
  }
}
