import {Routes} from '@angular/router';
import {DashboardComponent} from '../../pages/dashboard/dashboard.component';
import {AuthGuard} from '../auth/auth-guard.service';
import {ExecutivesComponent} from '../../pages/executives/executives.component';
import {CentresComponent} from '../../pages/centres/centres.component';
import {StatusesComponent} from '../../pages/statuses/statuses.component';
import {LabelsComponent} from '../../pages/labels/labels.component';
import {IssuesComponent} from '../../pages/issues/issues.component';
import {ActivityLogsComponent} from '../../pages/activity-logs/activity-logs.component';
import {AddExecutiveComponent} from '../../pages/executives/add-executive/add-executive.component';
import {AddCentreComponent} from '../../pages/centres/add-centre/add-centre.component';
import {AddIssueComponent} from '../../pages/issues/add-issue/add-issue.component';
import {AddStatusComponent} from '../../pages/statuses/add-status/add-status.component';
import {AddLabelComponent} from '../../pages/labels/add-label/add-label.component';
import {MyProfileComponent} from '../../pages/my-profile/my-profile.component';
import {SubjectsComponent} from '../../pages/subjects/subjects.component';
import {AddSubjectComponent} from '../../pages/subjects/add-subject/add-subject.component';
import {PaymentComponent} from '../../pages/centres/payment/payment.component';
import {CouriersComponent} from '../../pages/centres/couriers/couriers.component';
import {AddCourierComponent} from '../../pages/centres/couriers/add-courier/add-courier.component';
import {CouriersListComponent} from '../../pages/couriers-list/couriers-list.component';
import {ConversationsComponent} from '../../pages/conversations/conversations.component';
import {AdminsComponent} from '../../pages/admins/admins.component';
import {AddAdminComponent} from '../../pages/admins/add-admin/add-admin.component';
import {CourierExecutivesComponent} from '../../pages/courier-executives/courier-executives.component';
import {AddCourierExecutiveComponent} from '../../pages/courier-executives/add-courier-executive/add-courier-executive.component';
import {NotificationsComponent} from '../../pages/notifications/notifications.component';
import {AddNotificationComponent} from '../../pages/notifications/add-notification/add-notification.component';

//Route for content layout with sidebar, navbar and footer
export const Full_ROUTES: Routes = [
  {
    path: 'changelog',
    loadChildren: () => import('../../changelog/changelog.module').then(m => m.ChangeLogModule)
  },
  /*{
    path: 'full-layout',
    loadChildren: () => import('../../pages/full-layout-page/full-pages.module').then(m => m.FullPagesModule)
  }*/
  {
    path: 'dashboard',
    component: DashboardComponent,
    data: {
      title: 'Dashboard'
    },
    canActivate: [AuthGuard]
  },
  {
    path: 'executives',
    component: ExecutivesComponent,
    data: {
      title: 'Executives'
    },
    canActivate: [AuthGuard]
  },
  {
    path: 'executive/add',
    component: AddExecutiveComponent,
    data: {
      title: 'Add Executive'
    },
    canActivate: [AuthGuard]
  },
  {
    path: 'executive/:id/edit',
    component: AddExecutiveComponent,
    data: {
      title: 'Edit Executive'
    },
    canActivate: [AuthGuard]
  },
  {
    path: 'admins',
    component: AdminsComponent,
    data: {
      title: 'Admins'
    },
    canActivate: [AuthGuard]
  },
  {
    path: 'admin/add',
    component: AddAdminComponent,
    data: {
      title: 'Add Admin'
    },
    canActivate: [AuthGuard]
  },
  {
    path: 'admin/:id/edit',
    component: AddAdminComponent,
    data: {
      title: 'Edit Admin'
    },
    canActivate: [AuthGuard]
  },
  {
    path: 'courier-executives',
    component: CourierExecutivesComponent,
    data: {
      title: 'Courier Executives'
    },
    canActivate: [AuthGuard]
  },
  {
    path: 'courier-executive/add',
    component: AddCourierExecutiveComponent,
    data: {
      title: 'Add Courier Executive'
    },
    canActivate: [AuthGuard]
  },
  {
    path: 'courier-executive/:id/edit',
    component: AddCourierExecutiveComponent,
    data: {
      title: 'Edit Courier Executive'
    },
    canActivate: [AuthGuard]
  },
  {
    path: 'centres',
    component: CentresComponent,
    data: {
      title: 'Centres'
    },
    canActivate: [AuthGuard]
  },
  {
    path: 'centre/add',
    component: AddCentreComponent,
    data: {
      title: 'Add Centre'
    },
    canActivate: [AuthGuard]
  },
  {
    path: 'centre/:id/edit',
    component: AddCentreComponent,
    data: {
      title: 'Edit Centre'
    },
    canActivate: [AuthGuard]
  },
  {
    path: 'centre/:id/payment',
    component: PaymentComponent,
    data: {
      title: 'Payment'
    },
    canActivate: [AuthGuard]
  },
  {
    path: 'centre/:id/couriers',
    component: CouriersComponent,
    data: {
      title: 'Couriers'
    },
    canActivate: [AuthGuard]
  },
  {
    path: 'courier/:centre_id/add',
    component: AddCourierComponent,
    data: {
      title: 'Add Courier'
    },
    canActivate: [AuthGuard]
  },
  {
    path: 'centre/:centre_id/courier/:id/edit',
    component: AddCourierComponent,
    data: {
      title: 'Add Courier'
    },
    canActivate: [AuthGuard]
  },
  {
    path: 'statuses',
    component: StatusesComponent,
    data: {
      title: 'Statuses'
    },
    canActivate: [AuthGuard]
  },
  {
    path: 'status/add',
    component: AddStatusComponent,
    data: {
      title: 'Add Status'
    },
    canActivate: [AuthGuard]
  },
  {
    path: 'status/:id/edit',
    component: AddStatusComponent,
    data: {
      title: 'Edit Status'
    },
    canActivate: [AuthGuard]
  },
  {
    path: 'labels',
    component: LabelsComponent,
    data: {
      title: 'Labels'
    },
    canActivate: [AuthGuard]
  },
  {
    path: 'label/add',
    component: AddLabelComponent,
    data: {
      title: 'Add Label'
    },
    canActivate: [AuthGuard]
  },
  {
    path: 'label/:id/edit',
    component: AddLabelComponent,
    data: {
      title: 'Edit Label'
    },
    canActivate: [AuthGuard]
  },
  {
    path: 'centre/:id/issues',
    component: IssuesComponent,
    data: {
      title: 'Issues'
    },
    canActivate: [AuthGuard]
  },
  {
    path: 'issues/:id/add',
    component: AddIssueComponent,
    data: {
      title: 'Add Issue'
    },
    canActivate: [AuthGuard]
  },
  {
    path: 'activity-logs',
    component: ActivityLogsComponent,
    data: {
      title: 'Activity Logs'
    },
    canActivate: [AuthGuard]
  },
  {
    path: 'my-profile',
    component: MyProfileComponent,
    data: {
      title: 'My Profile'
    },
    canActivate: [AuthGuard]
  },
  {
    path: 'subjects',
    component: SubjectsComponent,
    data: {
      title: 'Subject'
    },
    canActivate: [AuthGuard]
  },
  {
    path: 'subject/add',
    component: AddSubjectComponent,
    data: {
      title: 'Add Subject'
    },
    canActivate: [AuthGuard]
  },
  {
    path: 'subject/:id/edit',
    component: AddSubjectComponent,
    data: {
      title: 'Edit Subject'
    },
    canActivate: [AuthGuard]
  },
  {
    path: 'couriers',
    component: CouriersListComponent,
    data: {
      title: 'Couriers'
    },
    canActivate: [AuthGuard]
  },
  {
    path: 'conversations',
    component: ConversationsComponent,
    data: {
      title: 'Conversations'
    },
    canActivate: [AuthGuard]
  },
  {
    path: 'conversations/:id',
    component: ConversationsComponent,
    data: {
      title: 'Conversations'
    },
    canActivate: [AuthGuard]
  },
  {
    path: 'notifications',
    component: NotificationsComponent,
    data: {
      title: 'Notifications'
    },
    canActivate: [AuthGuard]
  },
  {
    path: 'notification/add',
    component: AddNotificationComponent,
    data: {
      title: 'Add Notification'
    },
    canActivate: [AuthGuard]
  },
  {
    path: 'notification/:id',
    component: AddNotificationComponent,
    data: {
      title: 'Add Notification'
    },
    canActivate: [AuthGuard]
  }
];
