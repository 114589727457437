import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {BaseService} from './base.service';
import {Observable} from 'rxjs';
import {AlertService} from './alert.service';
import {GlobalService} from './global.service';
import {AppUrl} from '../constants/app-url';

@Injectable({
  providedIn: 'root'
})
export class AdminService extends BaseService {
  constructor(public http: HttpClient,
              public alertService: AlertService,
              public globalService: GlobalService) {
    super(http, alertService, globalService);
  }

  get(data): Observable<any> {
    return this.getRequest(AppUrl.ADMINS(), data);
  }

  count(): Observable<any> {
    return this.getRequest(AppUrl.ADMINS_COUNT());
  }

  detail(id): Observable<any> {
    return this.getRequest(AppUrl.ADMINS(id));
  }

  create(data): Observable<any> {
    return this.postRequest(AppUrl.ADMINS(), data);
  }

  update(id, data): Observable<any> {
    return this.putRequest(AppUrl.ADMINS(id), data);
  }

  updatePassword(id, data): Observable<any> {
    return this.putRequest(AppUrl.ADMIN_PASSWORD(id), data);
  }

  updateStatus(id, data): Observable<any> {
    return this.putRequest(AppUrl.ADMIN_STATUS(id), data);
  }
}
