import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {StorageService} from '../../shared/services/storage.service';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {NotificationService} from '../../shared/services/notification.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss']
})
export class NotificationsComponent implements OnInit {
  notifications = [];
  user: any;
  addNotificationForm: FormGroup;
  submitted = false;
  submitting = false;
  selectedNotification: any;
  pagination = {
    page: 1,
    total: null,
    perpage: 15,
    pages: null
  };

  constructor(private notificationService: NotificationService,
              private router: Router,
              public modalService: NgbModal,
              private fb: FormBuilder) {
  }

  ngOnInit() {
    this.user = StorageService.getItem('self');
    if (this.user.user_type !== 'SUPER_ADMIN') {
      this.router.navigateByUrl('');
    }
    this.refresh();
  }

  refresh() {
    this.addNotificationForm = this.fb.group({
      'startNotifying': ['', Validators.required],
      'endNotifying': ['', Validators.required],
      'message': ['', Validators.required]
    });
    this.get();
  }

  get(params: any = {}) {
    params.page = this.pagination.page;
    params.per_page = this.pagination.perpage;
    this.notificationService.get(params).subscribe(data => {
      if (data && data['data'].length > 0) {
        this.notifications = data['data'];
        this.pagination = {
          page: data.current_page,
          total: data.total,
          perpage: data.per_page,
          pages: data.last_page,
        }
      } else {
        this.notifications = [];
      }
    }, error => {
      this.submitting = false;
      this.submitted = false;
    });
  }

  notificationModel(content) {
    this.addNotificationForm.patchValue({
      'startNotifying': '',
      'endNotifying': '',
      'message': ''
    });
    this.selectedNotification = undefined;
    this.submitted = false;
    this.submitting = false;
    this.modalService.open(content, {'size': 'lg'});
  }

  delete(id) {
    this.notificationService.delete(id).subscribe(data => {
      this.get({});
    });
  }

  navigateTo(url) {
    this.router.navigateByUrl(url);
  }

  edit(notification) {
    this.router.navigateByUrl('notification/' + notification.id)
  }

  onPageChange(page) {
    this.pagination.page = page;
    this.get();
  }
}
