import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {AlertService} from '../../../shared/services/alert.service';
import {SubjectService} from '../../../shared/services/subject.service';
import {Location} from '@angular/common';

@Component({
  selector: 'app-add-subject',
  templateUrl: './add-subject.component.html',
  styleUrls: ['./add-subject.component.scss']
})
export class AddSubjectComponent implements OnInit {
  addSubjectForm: FormGroup;
  submitted: any = false;
  submitting: any = false;
  color: any;
  id: any;

  constructor(private router: Router,
              private fb: FormBuilder,
              private subjectService: SubjectService,
              private alertService: AlertService,
              private activatedRoute: ActivatedRoute,
              private _location: Location) {
  }

  ngOnInit() {
    this.id = this.activatedRoute.snapshot.params['id'];
    this.refresh();
  }

  refresh() {
    this.addSubjectForm = this.fb.group({
      'name': ['', Validators.required]
    });
    if (this.id) {
      this.detail(this.id);
    }
  }

  back() {
    // this.router.navigateByUrl('subjects');
    this._location.back();
  }

  detail(id) {
    this.subjectService.detail(parseInt(id)).subscribe(data => {
      if (data) {
        this.addSubjectForm.patchValue({
          'name': (data && data.name) ? data.name : ''
        });
      }
    }, error => {
      this.back();
    });
  }

  submit() {
    this.submitted = true;
    if (!this.addSubjectForm.valid) {
      return;
    }
    if (this.submitted) {
      const params = {
        'name': (this.addSubjectForm.value && this.addSubjectForm.value.name) ? this.addSubjectForm.value.name : '',
      };
      this.submitting = true;
      this.subjectService.create(params).subscribe(data => {
        if (data) {
          this.submitting = false;
          this.submitted = false;
          this.back();
        }
      }, error => {
        this.submitting = false;
        this.submitted = false;
      });
    }
  }

  update() {
    this.submitted = true;
    if (!this.addSubjectForm.valid) {
      return;
    }
    if (this.submitted) {
      const params = {
        'name': (this.addSubjectForm.value && this.addSubjectForm.value.name) ? this.addSubjectForm.value.name : ''
      };
      this.submitting = true;
      this.subjectService.update(this.id, params).subscribe(data => {
        this.submitting = false;
        this.submitted = false;
        this.back();
      }, error => {
        this.submitting = false;
        this.submitted = false;
      });
    }
  }
}
