import {Component, OnInit} from '@angular/core';
import {ActivityLogService} from '../../shared/services/activity-log.service';
import {Router} from '@angular/router';
import {ExecutiveService} from '../../shared/services/executive.service';
import {StorageService} from '../../shared/services/storage.service';
import {Location} from '@angular/common';

@Component({
  selector: 'app-activity-logs',
  templateUrl: './activity-logs.component.html',
  styleUrls: ['./activity-logs.component.scss']
})
export class ActivityLogsComponent implements OnInit {
  activityLogs = [];
  executives = [];
  user: any;
  pagination = {
    page: 1,
    total: null,
    perpage: 15,
    pages: null
  };

  constructor(private router: Router,
              private activityService: ActivityLogService,
              private executiveService: ExecutiveService,
              private _location: Location) {
  }

  ngOnInit() {
    this.user = StorageService.getItem('self');
    if (this.user.user_type === 'SUPER_ADMIN' || this.user.user_type === 'ADMIN') {
      this.getAllActivities();
      this.getExecutive();
    } else {
      this.getSelfActivities();
    }
  }

  getSelfActivities(params: any = {}) {
    params.page = this.pagination.page;
    params.per_page = this.pagination.perpage;
    this.activityService.getSelfActivities(params).subscribe(data => {
      if (data && data['data'].length > 0) {
        this.activityLogs = data['data'];
        this.pagination = {
          page: data.current_page,
          total: data.total,
          perpage: data.per_page,
          pages: data.last_page,
        }
      }
    });
  }

  getAllActivities(params: any = {}) {
    params.page = this.pagination.page;
    params.per_page = this.pagination.perpage;
    this.activityService.getAllActivities(params).subscribe(data => {
      if (data && data['data'].length > 0) {
        this.activityLogs = data['data'];
        this.pagination = {
          page: data.current_page,
          total: data.total,
          perpage: data.per_page,
          pages: data.last_page,
        }
      }
    });
  }

  getExecutive() {
    this.executiveService.get({'page': -1}).subscribe(data => {
      if (data && data['data'].length > 0) {
        this.executives = data['data'];
      }
    });
  }

  getExecutiveData(event) {
    if (event.target.value) {
      const params: any = {};
      this.pagination = {
        page: 1,
        total: null,
        perpage: 15,
        pages: null
      };
      params.page = this.pagination.page;
      params.per_page = this.pagination.perpage;
      this.activityLogs = [];
      this.activityService.getExecutiveActivities(event.target.value, params).subscribe(data => {
        if (data && data['data'].length > 0) {
          this.activityLogs = data['data'];
          this.pagination = {
            page: data.current_page,
            total: data.total,
            perpage: data.per_page,
            pages: data.last_page,
          }
        } else {
          this.activityLogs = [];
          this.pagination = {
            page: 1,
            total: null,
            perpage: 15,
            pages: null,
          };
        }
      });
    } else {
      this.pagination = {
        page: 1,
        total: null,
        perpage: 15,
        pages: null
      };
      this.getAllActivities();
    }
  }

  onPageChange(page) {
    this.pagination.page = page;
    this.getSelfActivities();
  }

  navigateTo(url) {
    this.router.navigateByUrl(url);
  }

  back() {
    // this.router.navigateByUrl('centres');
    this._location.back();
  }
}
