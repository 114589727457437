import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {SubjectService} from '../../shared/services/subject.service';

@Component({
  selector: 'app-subjects',
  templateUrl: './subjects.component.html',
  styleUrls: ['./subjects.component.scss']
})
export class SubjectsComponent implements OnInit {
  subjects = [];
  pagination = {
    page: 1,
    total: null,
    perpage: 15,
    pages: null
  };

  constructor(private subjectService: SubjectService,
              private router: Router) {
  }

  ngOnInit() {
    this.get();
  }

  get(params: any = {}) {
    params.page = this.pagination.page;
    params.per_page = this.pagination.perpage;
    this.subjects = [];
    this.subjectService.get(params).subscribe(data => {
      if (data && data['data'].length > 0) {
        this.subjects = data['data'];
        this.pagination = {
          page: data.current_page,
          total: data.total,
          perpage: data.per_page,
          pages: data.last_page,
        }
      }
    });
  }

  updateStatus(status, data) {
    const params = {
      'status': status
    };
    this.subjectService.updateStatus(data.id, params).subscribe(data => {
      this.get();
    });
  }

  navigateTo(url) {
    this.router.navigateByUrl(url);
  }

  edit(id) {
    const url = 'subject/' + id + '/edit';
    this.router.navigateByUrl(url);
  }

  onPageChange(page) {
    this.pagination.page = page;
    this.get();
  }
}
