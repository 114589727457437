import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {BaseService} from './base.service';
import {Observable} from 'rxjs';
import {AlertService} from './alert.service';
import {GlobalService} from './global.service';
import {AppUrl} from '../constants/app-url';

@Injectable({
  providedIn: 'root'
})
export class ConversationService extends BaseService {
  constructor(public http: HttpClient,
              public alertService: AlertService,
              public globalService: GlobalService) {
    super(http, alertService, globalService);
  }

  create(data): Observable<any> {
    return this.postRequest(AppUrl.CONVERSATIONS(), data);
  }

  detail(id): Observable<any> {
    return this.getRequest(AppUrl.CONVERSATIONS(id));
  }

  get(data): Observable<any> {
    return this.getRequest(AppUrl.CONVERSATIONS(), data);
  }

  getExecutivesConversation(id, data): Observable<any> {
    return this.getRequest(AppUrl.EXECUTIVE_CONVERSATIONS(id), data);
  }

  update(id, data): Observable<any> {
    return this.putRequest(AppUrl.CONVERSATIONS(id), data);
  }

  delete(id): Observable<any> {
    return this.deleteRequest(AppUrl.CONVERSATIONS(id));
  }
}
