import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {CentreService} from '../../../shared/services/centre.service';
import {StorageService} from '../../../shared/services/storage.service';
import {FormBuilder, FormGroup} from '@angular/forms';
import {ExecutiveService} from '../../../shared/services/executive.service';
import {BsDatepickerConfig} from 'ngx-bootstrap';
import * as moment from 'moment';
import {Location} from '@angular/common';

@Component({
  selector: 'app-couriers',
  templateUrl: './couriers.component.html',
  styleUrls: ['./couriers.component.scss']
})
export class CouriersComponent implements OnInit {
  couriers = [];
  id: any;
  courierData: any;
  user: any;
  showSearch = false;
  submitting = false;
  searchForm: FormGroup;
  executivesData = [];
  bsConfig: Partial<BsDatepickerConfig>;
  pagination = {
    page: 1,
    total: null,
    perpage: 15,
    pages: null
  };

  constructor(private centreService: CentreService,
              private router: Router,
              private activatedRoute: ActivatedRoute,
              private fb: FormBuilder,
              private executiveService: ExecutiveService,
              private _location: Location) {
    this.bsConfig = Object.assign({}, {containerClass: 'theme-dark-blue', dateInputFormat: 'YYYY-MM-DD'}, {showWeekNumbers: false});
  }

  ngOnInit() {
    this.id = this.activatedRoute.snapshot.params['id'];
    this.user = StorageService.getItem('self');
    this.refresh();
  }

  refresh() {
    this.searchForm = this.fb.group({
      'date': [''],
      'courierNo': [''],
      'executive_id': [''],
      'type': ['']
    });
    if (this.id) {
      this.detail(this.id);
    }
    this.getCouriers();
    this.getExecutives();
  }

  getCouriers(params: any = {}) {
    params.page = this.pagination.page;
    params.per_page = this.pagination.perpage;
    this.centreService.getCourier(this.id, params).subscribe(data => {
      if (data && data['data'].length > 0) {
        this.couriers = data['data'];
        this.pagination = {
          page: data.current_page,
          total: data.total,
          perpage: data.per_page,
          pages: data.last_page,
        }
      } else {
        this.couriers = [];
      }
    }, error => {
      this.submitting = false;
    });
  }

  getExecutives() {
    this.executiveService.get({'page': -1}).subscribe(data => {
      if (data && data['data'].length > 0) {
        this.executivesData = data['data'];
      }
    });
  }

  detail(id) {
    this.centreService.detail(parseInt(id)).subscribe(data => {
      if (data) {
        this.courierData = data;
      }
    }, error => {
      this.back()
    });
  }

  back() {
    // this.router.navigateByUrl('centres');
    this._location.back();
  }

  search() {
    let params = {
      'date': (this.searchForm.value && this.searchForm.value.date) ? moment(this.searchForm.value.date).format('YYYY-MM-DD') : '',
      'courier_no': (this.searchForm.value && this.searchForm.value.courierNo) ? this.searchForm.value.courierNo : '',
      'executive_id': (this.searchForm.value && this.searchForm.value.executive_id) ? this.searchForm.value.executive_id : '',
      'type': (this.searchForm.value && this.searchForm.value.type) ? this.searchForm.value.type : '',
    };
    this.getCouriers(params);
  }

  navigateTo(url) {
    this.router.navigateByUrl(url);
  }

  edit(id) {
    const url = 'centre/' + this.id + '/courier/' + id + '/edit';
    this.router.navigateByUrl(url);
  }

  onPageChange(page) {
    this.pagination.page = page;
    let params = {
      'date': (this.searchForm.value && this.searchForm.value.date) ? moment(this.searchForm.value.date).format('YYYY-MM-DD') : '',
      'courier_no': (this.searchForm.value && this.searchForm.value.courierNo) ? this.searchForm.value.courierNo : '',
      'executive_id': (this.searchForm.value && this.searchForm.value.executive_id) ? this.searchForm.value.executive_id : '',
      'type': (this.searchForm.value && this.searchForm.value.type) ? this.searchForm.value.type : '',
    };
    this.getCouriers(params);
  }
}
