import {Component, OnInit} from '@angular/core';
import {ExecutiveService} from '../../shared/services/executive.service';
import {CentreService} from '../../shared/services/centre.service';
import {IssueService} from '../../shared/services/issue.service';
import {StorageService} from '../../shared/services/storage.service';
import {NotificationService} from '../../shared/services/notification.service';
import * as moment from 'moment'

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
  user: any;
  selectedExecutive: any;
  executivesData = [];
  notifications = [];
  executives = 0;
  couriers = 0;
  centres = 0;
  issues = 0;
  pendingCount = 0;
  inProgressCount = 0;
  resolvedCount = 0;
  centresEnrolledByYou = 0;
  issuesAddedByYou = 0;
  issuesInProgressByYou = 0;
  issuesResolvedByYou = 0;

  constructor(private executiveService: ExecutiveService,
              private centreService: CentreService,
              private issueService: IssueService,
              private notificationService: NotificationService) {
  }

  ngOnInit() {
    this.user = StorageService.getItem('self');
    if (this.user.user_type === 'SUPER_ADMIN' || this.user.user_type === 'ADMIN') {
      this.getExecutives();
    } else {
      this.centresAddedbyUserCount();
      this.issuesAddedByUserCount();
      this.issuesInProgressByUserCount();
      this.issuesResolvedByUserCount();
      this.getRecentNotifications();
    }
    this.selectedExecutive = this.user;
    this.executivesCount();
    this.centresCount();
    this.couriersCount();
    this.issuesCount();
    this.pendingIssuesCount();
    this.resolvedIssuesCount();
    this.inProgressIssuesCount();
  }

  getExecutives() {
    this.executiveService.get({'page': -1}).subscribe(data => {
      if (data && data['data'].length > 0) {
        this.executivesData = data['data'];
      }
    });
  }

  getExecutiveData(event) {
    if (event.target.value) {
      if (this.executivesData && this.executivesData.length > 0) {
        this.executivesData.forEach(executive => {
          if (parseInt(executive.id) === parseInt(event.target.value)) {
            this.selectedExecutive = {
              'name': executive.name,
              'userId': executive.id
            }
          }
        });
      }
      this.centresAddedbyUserCount(event.target.value);
      this.issuesAddedByUserCount(event.target.value);
      this.issuesInProgressByUserCount(event.target.value)
      this.issuesResolvedByUserCount(event.target.value);
    } else {
      this.selectedExecutive = undefined;
    }
  }

  executivesCount() {
    this.executiveService.count().subscribe(data => {
      this.executives = data;
    });
  }

  getRecentNotifications() {
    this.notificationService.get({'page': -1}).subscribe(data => {
      if (data && data['data'].length > 0) {
        this.notifications = data['data'];
        if (this.notifications && this.notifications.length > 0) {
          let notificationArr = [];
          this.notifications.forEach(notification => {
            if (moment().diff(notification.start_date_time, 'minutes') >= 0 && moment().diff(notification.end_date_time, 'minutes') < 0) {
              notificationArr.push(notification);
            }
          });
          this.notifications = notificationArr;
        }
      }
    });
  }

  centresCount() {
    this.centreService.count().subscribe(data => {
      this.centres = data;
    });
  }

  couriersCount() {
    this.centreService.getCourierCount().subscribe(data => {
      this.couriers = data;
    });
  }

  issuesCount() {
    this.issueService.count().subscribe(data => {
      this.issues = data;
    });
  }

  pendingIssuesCount() {
    this.issueService.pendingCount().subscribe(data => {
      this.pendingCount = data;
    });
  }

  inProgressIssuesCount() {
    this.issueService.inProgressCount().subscribe(data => {
      this.inProgressCount = data;
    });
  }

  resolvedIssuesCount() {
    this.issueService.resolvedCount().subscribe(data => {
      this.resolvedCount = data;
    });
  }

  centresAddedbyUserCount(id?) {
    if (id) {
      this.centreService.centresAddedByUserCount(id).subscribe(data => {
        this.centresEnrolledByYou = data;
      });
    } else {
      this.centreService.centresAddedByUserCount().subscribe(data => {
        this.centresEnrolledByYou = data;
      });
    }
  }

  issuesAddedByUserCount(id?) {
    if (id) {
      this.issueService.issuesAddedByUserCount(id).subscribe(data => {
        this.issuesAddedByYou = data;
      });
    } else {
      this.issueService.issuesAddedByUserCount().subscribe(data => {
        this.issuesAddedByYou = data;
      });
    }
  }

  issuesInProgressByUserCount(id?) {
    if (id) {
      this.issueService.issuesInProgressByUserCount(id).subscribe(data => {
        this.issuesInProgressByYou = data;
      });
    } else {
      this.issueService.issuesInProgressByUserCount().subscribe(data => {
        this.issuesInProgressByYou = data;
      });
    }
  }

  issuesResolvedByUserCount(id?) {
    if (id) {
      this.issueService.issuesResolvedByUserCount(id).subscribe(data => {
        this.issuesResolvedByYou = data;
      });
    } else {
      this.issueService.issuesResolvedByUserCount().subscribe(data => {
        this.issuesResolvedByYou = data;
      });
    }
  }
}
