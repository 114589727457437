import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ExecutiveService} from '../../../shared/services/executive.service';
import {AlertService} from '../../../shared/services/alert.service';
import {Location} from '@angular/common';

@Component({
  selector: 'app-add-executive',
  templateUrl: './add-executive.component.html',
  styleUrls: ['./add-executive.component.scss']
})
export class AddExecutiveComponent implements OnInit {
  addExecutiveForm: FormGroup;
  submitted: any = false;
  submitting: any = false;
  inputTypePassword: any = 'password';
  id: any;

  constructor(private router: Router,
              private fb: FormBuilder,
              private executiveService: ExecutiveService,
              public activatedRoute: ActivatedRoute,
              private alertService: AlertService,
              private _location: Location) {
  }

  ngOnInit() {
    this.id = this.activatedRoute.snapshot.params['id'];
    this.refresh();
  }

  refresh() {
    this.addExecutiveForm = this.fb.group({
      'name': ['', Validators.required],
      'username': ['', (!this.id) ? Validators.required : ''],
      'email': ['', Validators.compose([Validators.required, Validators.pattern('^(([^<>()[\\]\\\\.,;:\\s@\\"]+(\\.[^<>()[\\]\\\\.,;:\\s@\\"]+)*)|(\\".+\\"))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))$')])],
      'contact': ['', Validators.compose([Validators.required, Validators.pattern('^[5-9]{1}[0-9]{9}$')])],
      'password': ['', (!this.id) ? Validators.required : '']
    });

    if (this.id) {
      this.detail(this.id);
    }
  }

  detail(id) {
    this.executiveService.detail(parseInt(id)).subscribe(data => {
      if (data) {
        this.addExecutiveForm.patchValue({
          'name': (data && data.name) ? data.name : '',
          'username': (data && data.username) ? data.username : '',
          'email': (data && data.email) ? data.email : '',
          'contact': (data && data.contact) ? data.contact : ''
        });
      }
    }, error => {
      this.back();
    });
  }

  back() {
    // this.router.navigateByUrl('executives');
    this._location.back();
  }

  submit() {
    this.submitted = true;
    if (!this.addExecutiveForm.valid) {
      return;
    }
    if (this.submitted) {
      const params = {
        'name': (this.addExecutiveForm.value && this.addExecutiveForm.value.name) ? this.addExecutiveForm.value.name : '',
        'username': (this.addExecutiveForm.value && this.addExecutiveForm.value.username) ? this.addExecutiveForm.value.username : '',
        'email': (this.addExecutiveForm.value && this.addExecutiveForm.value.email) ? this.addExecutiveForm.value.email : '',
        'contact': (this.addExecutiveForm.value && this.addExecutiveForm.value.contact) ? this.addExecutiveForm.value.contact : '',
        'password': (this.addExecutiveForm.value && this.addExecutiveForm.value.password) ? this.addExecutiveForm.value.password : ''
      };
      this.submitting = true;
      this.executiveService.create(params).subscribe(data => {
        if (data) {
          this.submitting = false;
          this.submitted = false;
          this.back();
        }
      }, error => {
        this.submitting = false;
        this.submitted = false;
        if (error && error['error'] && error['error']['errors'] && error['error'].hasOwnProperty('errors')) {
          if (error['error']['errors'].username && error['error']['errors'].username.length > 0) {
            this.alertService.showErrors(error['error']['errors'].username[0]);
            return;
          }
          if (error['error']['errors'].email && error['error']['errors'].email.length > 0) {
            this.alertService.showErrors(error['error']['errors'].email[0]);
            return;
          }
          if (error['error']['errors'].contact && error['error']['errors'].contact.length > 0) {
            this.alertService.showErrors(error['error']['errors'].contact[0]);
            return;
          }
        }
      });
    }
  }

  update() {
    this.submitted = true;
    if (!this.addExecutiveForm.valid) {
      return;
    }
    if (this.submitted) {
      const params = {
        'name': (this.addExecutiveForm.value && this.addExecutiveForm.value.name) ? this.addExecutiveForm.value.name : '',
        'username': (this.addExecutiveForm.value && this.addExecutiveForm.value.username) ? this.addExecutiveForm.value.username : '',
        'email': (this.addExecutiveForm.value && this.addExecutiveForm.value.email) ? this.addExecutiveForm.value.email : '',
        'contact': (this.addExecutiveForm.value && this.addExecutiveForm.value.contact) ? this.addExecutiveForm.value.contact : ''
      };
      this.submitting = true;
      this.executiveService.update(this.id, params).subscribe(data => {
        this.submitting = false;
        this.submitted = false;
        this.back();
      }, error => {
        this.submitting = false;
        this.submitted = false;
        if (error && error['error'] && error['error']['errors'] && error['error'].hasOwnProperty('errors')) {
          if (error['error']['errors'].username && error['error']['errors'].username.length > 0) {
            this.alertService.showErrors(error['error']['errors'].username[0]);
            return;
          }
          if (error['error']['errors'].email && error['error']['errors'].email.length > 0) {
            this.alertService.showErrors(error['error']['errors'].email[0]);
            return;
          }
          if (error['error']['errors'].contact && error['error']['errors'].contact.length > 0) {
            this.alertService.showErrors(error['error']['errors'].contact[0]);
            return;
          }
        }
      });
    }
  }
}
